import { Action } from "easy-peasy";

export enum ACCOUNT_TYPE {
  normal = "normal",
  superAdmin = "superAdmin",
}
export type TAcountType =
  | ACCOUNT_TYPE.normal
  | ACCOUNT_TYPE.superAdmin


export type TOrgRole = "user" | "admin";

export interface UserContract {
  name: string;
  id: string;
}
export interface IUserFlage {
  [key:string]: boolean
}

export interface IUserDetails {
  authenticated: boolean;
  uid?: string;
  accountType: TAcountType;
  loading: boolean;
  email: string;
  orgStatus:boolean;
  flags:IUserFlage[]
  lang: string;
  orgName:string
  orgID:string
  orgRole: TOrgRole;
  contracts:UserContract[]
  orgSesionID:string
}

export interface IUser {
  userDetails: IUserDetails;
  //actions
  resetUser: Action<IUser, void>;
  setAuthenticated: Action<IUser, boolean>;
  setUserDetails: Action<IUser, IUserDetails>;
  addContractToUser:Action<IUser,UserContract>;
  removeContractFromUser:Action<IUser,string>;
  editContractName:Action<IUser,{contractID:string, name:string}>
}
