import React, { FC, useEffect, useRef, useState } from "react";
import {
  ADDITIONAL_TYPES,
  IAddtionalElements,
  TAdditionalType,
} from "../../definitions/additional";
import {
  AdditionalElementsWrapper,
  Cloud,
  Dot,
  Edge,
  ElementControl,
  Triage,
} from "./AdditionalElements.styles";
import {
  calculateMapElementArc,
  calculateMapElementHeight,
  calculateMapElementWidth,
  checkPropInElement,
  getArc,
  getCordinates,
  getHeight,
  getWidth,
} from "./additionalHelper";
import { validatePosition } from "../../helpers/pdfHelpers";
import { createRoot } from "react-dom/client";
import { Icon } from "venice-ui";
import { Colors } from "../../untils/Theme";

interface IAdditionalElements {
  element: IAddtionalElements;
  scale: number;
  editMode: boolean;
  active: boolean;
  pdfHeight?: number;
  pdfWidth?: number;
  // onClick?: any;
  showDetails: (
    id: string,
    isMapElement: boolean,
    isReference: boolean
  ) => void;
  saveChanges: (element: IAddtionalElements, isSource: boolean) => void;
}
export interface IElementData {
  move: boolean;
  x: number;
  y: number;
  editMode: boolean;
  elementMode: TAdditionalType;
  pageHeight: number;
  pageWidth: number;
  height: number;
  width: number;
  rotate: number;
  size: number;
  end_x: number;
  end_y: number;
  mode: string;
}

export const AdditionalElements: FC<IAdditionalElements> = ({
  element,
  scale,
  active,
  editMode,
  pdfHeight = 0,
  pdfWidth = 0,
  showDetails,
  saveChanges,
}) => {
  const [elementData, _setElementData] = useState<IElementData>({
    move: false,
    editMode: editMode,
    x: element.x * scale,
    y: element.y * scale,
    elementMode: element.type,
    pageHeight: pdfHeight,
    pageWidth: pdfWidth,
    rotate: getArc(element),
    width: getWidth(element) * scale,
    height: getHeight(element) * scale,
    size: (checkPropInElement(element, "size", "number") as number) || 0,
    end_x: (checkPropInElement(element, "end_x", "number") as number) || 0,
    end_y: (checkPropInElement(element, "end_y", "number") as number) || 0,
    mode: "element",
  });

  const elementDataRef = useRef(elementData);
  const setElementData = (data: IElementData) => {
    elementDataRef.current = data;
    _setElementData(data);
  };

  useEffect(() => {
    setElementData({
      ...elementDataRef.current,
      x: element.x * scale,
      y: element.y * scale,
      end_x:
        ((checkPropInElement(element, "end_x", "number") as number) || 0) *
        scale,
      end_y:
        ((checkPropInElement(element, "end_y", "number") as number) || 0) *
        scale,
      pageHeight: pdfHeight,
      pageWidth: pdfWidth,
      editMode: editMode,
      width: getWidth(element) * scale,
      height: getHeight(element) * scale,
      rotate: getArc(element),
      size: (checkPropInElement(element, "size", "number") as number) || 0,
    });
  }, [
    pdfHeight,
    pdfWidth,
    scale,
    editMode,
    element.size,
    element.rotation,
    element.height,
  ]);
  const udpdateElement = (
    x: number,
    y: number,
    end_x: number,
    end_y: number,
    size: number,
    rotation: number,
    height: number
  ) => {
    const updatedElement = { ...element };
    updatedElement.x = x;
    updatedElement.y = y;
    updatedElement.end_x = end_x;
    updatedElement.end_y = end_y;
    updatedElement.size = size;
    updatedElement.rotation = rotation;
    updatedElement.height = height;
    saveChanges(updatedElement, false);
  };

  const handleMouseDown = (event: any) => {
    event.stopPropagation();
    console.log();
    showDetails(element.id!, true, false);
  };

  let startX = 0;
  let startY = 0;
  let pointLeft = 0;
  let pointTop = 0;
  let endLeft = 0;
  let endTop = 0;
  let deltaLeft = 0;
  let deltaTop = 0;

  const dragImage = (event: any) => {
    let image: JSX.Element = <span></span>;
    var ghost = document.createElement("div");
    ghost.style.transform = "translate(-10000px, -10000px)";
    ghost.style.position = "absolute";
    document.body.appendChild(ghost);
    event.dataTransfer.setDragImage(ghost, 0, 0);
    event.dataTransfer.dropEffect = "move";
    event.dataTransfer.effectAllowed = "move";
    // ReactDOM.render(image, ghost);
    createRoot(ghost).render(image);
  };

  const dragElementStart = (event: any) => {
    pointLeft = elementDataRef.current.x;
    pointTop = elementDataRef.current.y;
    endLeft = elementDataRef.current.end_x;
    endTop = elementDataRef.current.end_y;
    event.stopPropagation();
    if (!element.lock) {
      startX = event.clientX;
      startY = event.clientY;
      setElementData({
        ...elementDataRef.current,
        move: true,
        mode: "element",
      });

      dragImage(event);
    }
  };
  const dragControlStart = (event: any, mode: string) => {
    pointLeft = elementDataRef.current.x;
    pointTop = elementDataRef.current.y;
    endLeft = elementDataRef.current.end_x;
    endTop = elementDataRef.current.end_y;
    event.stopPropagation();
    if (!element.lock) {
      startX = event.clientX;
      startY = event.clientY;
      setElementData({
        ...elementDataRef.current,
        move: true,
        mode: mode,
      });

      dragImage(event);
    }
  };

  const drag = (event: any) => {
    if (element.lock) return;
    event.stopPropagation();
    deltaLeft = event.clientX - startX;
    deltaTop = event.clientY - startY;
    if (
      deltaLeft !== 0 &&
      deltaTop !== 0 &&
      event.clientX !== 0 &&
      event.clientY !== 0
    ) {
      setElementData({
        ...elementDataRef.current,
        x: pointLeft + deltaLeft,
        y: pointTop + deltaTop,
        end_x: endLeft + deltaLeft,
        end_y: endTop + deltaTop,
      });
    }
  };

  const controloDrag = (event: any) => {
    if (element.lock) return;
    event.stopPropagation();
    const { mode } = elementDataRef.current;
    deltaLeft = event.clientX - startX;
    deltaTop = event.clientY - startY;

    if (
      deltaLeft !== 0 &&
      deltaTop !== 0 &&
      event.clientX !== 0 &&
      event.clientY !== 0
    ) {
      switch (mode) {
        case "edgeStart":
          setElementData({
            ...elementDataRef.current,
            x: pointLeft + deltaLeft,
            y: pointTop + deltaTop,
          });
          break;
        case "edgeEnd":
          setElementData({
            ...elementDataRef.current,
            end_x: endLeft + deltaLeft,
            end_y: endTop + deltaTop,
          });
          break;
        case "nwPoint":
          setElementData({
            ...elementDataRef.current,
            x: pointLeft + deltaLeft,
            y: pointTop + deltaTop,
          });
          break;
        case "nePoint":
          setElementData({
            ...elementDataRef.current,
            end_x: endLeft + deltaLeft,
            y: pointTop + deltaTop,
          });
          break;
        case "sePoint":
          setElementData({
            ...elementDataRef.current,
            end_x: endLeft + deltaLeft,
            end_y: endTop + deltaTop,
          });
          break;
        case "swPoint":
          setElementData({
            ...elementDataRef.current,
            x: pointLeft + deltaLeft,
            end_y: endTop + deltaTop,
          });
          break;
      }
    }
  };

  const dragEnd = (event: any) => {
    const { mode } = elementDataRef.current;
    const deltaLeft = event.clientX - startX;
    const deltaTop = event.clientY - startY;
    event.stopPropagation();
    //ToDo add posiiton validation !!
    if (!element.lock) {
      // const { left, top } = validatePosition(
      //   pointTop + deltaTop,
      //   pointLeft + deltaLeft,
      //   endTop + deltaTop,
      //   endLeft + deltaLeft,
      //   0,
      //   elementDataRef.current.pageHeight,
      //   0,
      //   elementDataRef.current.pageWidth,
      //   true
      // );
      // elementX = left;
      // elementY = top;

      //   elementX = elementX + deltaLeft;
      //   elementY = elementY + deltaTop;
      switch (mode) {
        case "element":
          setElementData({
            ...elementDataRef.current,
            x: pointLeft + deltaLeft,
            y: pointTop + deltaTop,
            end_x: endLeft + deltaLeft,
            end_y: endTop + deltaTop,
            move: false,
          });
          udpdateElement(
            (pointLeft + deltaLeft) / scale,
            (pointTop + deltaTop) / scale,
            (endLeft + deltaLeft) / scale,
            (endTop + deltaTop) / scale,
            elementDataRef.current.size,
            elementDataRef.current.rotate,
            elementDataRef.current.height
          );
          break;
        case "edgeStart":
          setElementData({
            ...elementDataRef.current,
            x: pointLeft + deltaLeft,
            y: pointTop + deltaTop,
            move: false,
            mode: "element",
          });
          udpdateElement(
            (pointLeft + deltaLeft) / scale,
            (pointTop + deltaTop) / scale,
            elementDataRef.current.end_x / scale,
            elementDataRef.current.end_y / scale,
            elementDataRef.current.size,
            elementDataRef.current.rotate,
            elementDataRef.current.height / scale
          );
          break;
        case "edgeEnd":
          setElementData({
            ...elementDataRef.current,
            end_x: endLeft + deltaLeft,
            end_y: endTop + deltaTop,
            move: false,
            mode: "element",
          });
          udpdateElement(
            elementDataRef.current.x / scale,
            elementDataRef.current.y / scale,
            (endLeft + deltaLeft) / scale,
            (endTop + deltaTop) / scale,
            elementDataRef.current.size,
            elementDataRef.current.rotate,
            elementDataRef.current.height / scale
          );
          break;
        case "nwPoint":
          setElementData({
            ...elementDataRef.current,
            x: pointLeft + deltaLeft,
            y: pointTop + deltaTop,
            move: false,
            mode: "element",
          });
          udpdateElement(
            (pointLeft + deltaLeft) / scale,
            (pointTop + deltaTop) / scale,
            elementDataRef.current.end_x,
            elementDataRef.current.end_y,
            elementDataRef.current.size,
            elementDataRef.current.rotate,
            elementDataRef.current.height
          );
          break;
        case "nePoint":
          setElementData({
            ...elementDataRef.current,
            end_x: endLeft + deltaLeft,
            y: pointTop + deltaTop,
            move: false,
            mode: "element",
          });
          udpdateElement(
            elementDataRef.current.x,
            (pointTop + deltaTop) / scale,
            (endLeft + deltaLeft) / scale,
            elementDataRef.current.end_y,
            elementDataRef.current.size,
            elementDataRef.current.rotate,
            elementDataRef.current.height
          );
          break;
        case "sePoint":
          setElementData({
            ...elementDataRef.current,
            end_x: endLeft + deltaLeft,
            end_y: endTop + deltaTop,
            move: false,
            mode: "element",
          });
          udpdateElement(
            elementDataRef.current.x,
            elementDataRef.current.y,
            (endLeft + deltaLeft) / scale,
            (endTop + deltaTop) / scale,
            elementDataRef.current.size,
            elementDataRef.current.rotate,
            elementDataRef.current.height
          );
          break;
        case "swPoint":
          setElementData({
            ...elementDataRef.current,
            x: pointLeft + deltaLeft,
            end_y: endTop + deltaTop,
            move: false,
            mode: "element",
          });
          udpdateElement(
            (pointLeft + deltaLeft) / scale,
            elementDataRef.current.y,
            elementDataRef.current.end_x,
            (endTop + deltaTop) / scale,
            elementDataRef.current.size,
            elementDataRef.current.rotate,
            elementDataRef.current.height
          );
          break;
      }
    }
  };

  const mouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    showDetails(element.id!, true, false);
  };

  const pointRef = useRef<HTMLDivElement>(null);
  let point = pointRef.current;

  const edgeStartRef = useRef<HTMLDivElement>(null);
  const edgeEndRef = useRef<HTMLDivElement>(null);
  let edgeStart = edgeStartRef.current;
  let edgeEnd = edgeEndRef.current;

  const nwRef = useRef<HTMLDivElement>(null);
  const neRef = useRef<HTMLDivElement>(null);
  const seRef = useRef<HTMLDivElement>(null);
  const swRef = useRef<HTMLDivElement>(null);

  let nwPoint = nwRef.current;
  let nePoint = neRef.current;
  let sePoint = seRef.current;
  let swPoint = swRef.current;

  useEffect(() => {
    point = pointRef.current;
    edgeStart = edgeStartRef.current;
    edgeEnd = edgeEndRef.current;
    nwPoint = nwRef.current;
    nePoint = neRef.current;
    sePoint = seRef.current;
    swPoint = swRef.current;
    if (point) {
      point.addEventListener("dragstart", dragElementStart);
      point.addEventListener("mousedown", handleMouseDown);
      point.addEventListener("drag", drag);
      point.addEventListener("dragend", dragEnd);
    }
    if (edgeStart) {
      edgeStart.addEventListener("dragstart", (e) =>
        dragControlStart(e, "edgeStart")
      );
      edgeStart.addEventListener("mousedown", handleMouseDown);
      edgeStart.addEventListener("drag", controloDrag);
      edgeStart.addEventListener("dragend", dragEnd);
    }
    if (edgeEnd) {
      edgeEnd.addEventListener("dragstart", (e) =>
        dragControlStart(e, "edgeEnd")
      );
      edgeEnd.addEventListener("mousedown", handleMouseDown);
      edgeEnd.addEventListener("drag", controloDrag);
      edgeEnd.addEventListener("dragend", dragEnd);
    }
    if (nwPoint) {
      nwPoint.addEventListener("dragstart", (e) =>
        dragControlStart(e, "nwPoint")
      );
      nwPoint.addEventListener("mousedown", handleMouseDown);
      nwPoint.addEventListener("drag", controloDrag);
      nwPoint.addEventListener("dragend", dragEnd);
    }
    if (nePoint) {
      nePoint.addEventListener("dragstart", (e) =>
        dragControlStart(e, "nePoint")
      );
      nePoint.addEventListener("mousedown", handleMouseDown);
      nePoint.addEventListener("drag", controloDrag);
      nePoint.addEventListener("dragend", dragEnd);
    }
    if (sePoint) {
      sePoint.addEventListener("dragstart", (e) =>
        dragControlStart(e, "sePoint")
      );
      sePoint.addEventListener("mousedown", handleMouseDown);
      sePoint.addEventListener("drag", controloDrag);
      sePoint.addEventListener("dragend", dragEnd);
    }
    if (swPoint) {
      swPoint.addEventListener("dragstart", (e) =>
        dragControlStart(e, "swPoint")
      );
      swPoint.addEventListener("mousedown", handleMouseDown);
      swPoint.addEventListener("drag", controloDrag);
      swPoint.addEventListener("dragend", dragEnd);
    }
    return () => {
      if (point) {
        point.removeEventListener("dragstart", dragElementStart);
        point.removeEventListener("drag", drag);
        point.removeEventListener("mousedown", handleMouseDown);
        point.removeEventListener("dragend", dragEnd);
      }
      if (edgeStart) {
        edgeStart.removeEventListener("dragstart", (e) =>
          dragControlStart(e, "edgeStart")
        );
        edgeStart.removeEventListener("mousedown", handleMouseDown);
        edgeStart.removeEventListener("drag", controloDrag);
        edgeStart.removeEventListener("dragend", dragEnd);
      }
      if (edgeEnd) {
        edgeEnd.removeEventListener("dragstart", (e) =>
          dragControlStart(e, "edgeEnd")
        );
        edgeEnd.removeEventListener("mousedown", handleMouseDown);
        edgeEnd.removeEventListener("drag", controloDrag);
        edgeEnd.removeEventListener("dragend", dragEnd);
      }
      if (nwPoint) {
        nwPoint.removeEventListener("dragstart", (e) =>
          dragControlStart(e, "nwPoint")
        );
        nwPoint.removeEventListener("mousedown", handleMouseDown);
        nwPoint.removeEventListener("drag", controloDrag);
        nwPoint.removeEventListener("dragend", dragEnd);
      }
      if (nePoint) {
        nePoint.removeEventListener("dragstart", (e) =>
          dragControlStart(e, "nePoint")
        );
        nePoint.removeEventListener("mousedown", handleMouseDown);
        nePoint.removeEventListener("drag", controloDrag);
        nePoint.removeEventListener("dragend", dragEnd);
      }
      if (sePoint) {
        sePoint.removeEventListener("dragstart", (e) =>
          dragControlStart(e, "sePoint")
        );
        sePoint.removeEventListener("mousedown", handleMouseDown);
        sePoint.removeEventListener("drag", controloDrag);
        sePoint.removeEventListener("dragend", dragEnd);
      }
      if (swPoint) {
        swPoint.removeEventListener("dragstart", (e) =>
          dragControlStart(e, "swPoint")
        );
        swPoint.removeEventListener("mousedown", handleMouseDown);
        swPoint.removeEventListener("drag", controloDrag);
        swPoint.removeEventListener("dragend", dragEnd);
      }
    };
  }, [
    pointRef,
    scale,
    edgeStartRef,
    edgeEndRef,
    nwRef,
    neRef,
    seRef,
    swRef,
    active,
  ]);
  return (
    <>
      <AdditionalElementsWrapper
        ref={pointRef}
        isActive={false}
        isEditMode={elementDataRef.current.editMode}
        onClick={(e) => mouseDown(e)}
        onMouseDown={(e) => mouseDown(e)}
        origin={element.type === "edge"}
        style={{
          left:
            getCordinates(
              elementDataRef.current.x,
              elementDataRef.current.width,
              elementDataRef.current.elementMode
            ) + "px",
          top:
            getCordinates(
              elementDataRef.current.y,
              elementDataRef.current.height,
              elementDataRef.current.elementMode
            ) + "px",
          height: calculateMapElementHeight(elementDataRef.current) + "px",
          width: calculateMapElementWidth(elementDataRef.current) + "px",
          transform: `rotate(${calculateMapElementArc(
            elementDataRef.current
          )}deg)`,
        }}
      >
        {element.type === ADDITIONAL_TYPES.dot && <Dot isActive={active} />}
        {element.type === ADDITIONAL_TYPES.triage && (
          <Triage isActive={active} size={elementDataRef.current.width} />
        )}
        {element.type === ADDITIONAL_TYPES.edge && (
          <>
            <Edge isActive={active} />
            {active && (
              <>
                <ElementControl
                  ref={edgeStartRef}
                  onMouseDown={(e) => handleMouseDown(e)}
                  style={{
                    left: "-24px",
                    top: elementDataRef.current.height / 2 - 12 + "px",
                  }}
                >
                  <Icon
                    name="move"
                    iconBgHoverColor="tranparent"
                    iconColor={Colors.seaBlue}
                    size={16}
                    noPadding={true}
                  />
                </ElementControl>
                <ElementControl
                  ref={edgeEndRef}
                  onMouseDown={(e) => handleMouseDown(e)}
                  style={{
                    right: "-24px",
                    top: elementDataRef.current.height / 2 - 12 + "px",
                  }}
                >
                  <Icon
                    name="move"
                    iconBgHoverColor="tranparent"
                    iconColor={Colors.seaBlue}
                    size={16}
                    noPadding={true}
                  />
                </ElementControl>
              </>
            )}
          </>
        )}
        {element.type === ADDITIONAL_TYPES.cloud && (
          <>
            <Cloud isActive={active} factor={scale} />
            {active && (
              <>
                <ElementControl
                  ref={nwRef}
                  onMouseDown={(e) => handleMouseDown(e)}
                  style={{
                    left: "-16px",
                    top: "-16px",
                  }}
                >
                  <Icon
                    name="move_nw"
                    iconBgHoverColor="tranparent"
                    iconColor={Colors.seaBlue}
                    size={16}
                    noPadding={true}
                  />
                </ElementControl>
                <ElementControl
                  ref={neRef}
                  onMouseDown={(e) => handleMouseDown(e)}
                  style={{
                    right: "-16px",
                    top: "-16px",
                  }}
                >
                  <Icon
                    name="move_ne"
                    iconBgHoverColor="tranparent"
                    iconColor={Colors.seaBlue}
                    size={16}
                    noPadding={true}
                  />
                </ElementControl>
                <ElementControl
                  ref={seRef}
                  onMouseDown={(e) => handleMouseDown(e)}
                  style={{
                    right: "-16px",
                    bottom: "-16px",
                  }}
                >
                  <Icon
                    name="move_se"
                    iconBgHoverColor="tranparent"
                    iconColor={Colors.seaBlue}
                    size={16}
                    noPadding={true}
                  />
                </ElementControl>
                <ElementControl
                  ref={swRef}
                  onMouseDown={(e) => handleMouseDown(e)}
                  style={{
                    left: "-16px",
                    bottom: "-16px",
                  }}
                >
                  <Icon
                    name="move_sw"
                    iconBgHoverColor="tranparent"
                    iconColor={Colors.seaBlue}
                    size={16}
                    noPadding={true}
                  />
                </ElementControl>
              </>
            )}
          </>
        )}
      </AdditionalElementsWrapper>
    </>
  );
};
