import styled from "styled-components";
import { TextSubHeader,Text } from "venice-ui";

export const OrganizationsWrapper = styled.div`
    width:calc(100% - 35rem);
    display:flex;
    flex-direction:column;
    gap:10px;
    height: calc(100vh - 16rem);
    align-items: flex-start;
    padding-right:0.5rem;
    box-sizing:border:box;
`;
export const OrganizationDetailsWrapper = styled.div`
    width:35rem;
    display:flex;
    height: calc(100vh - 16rem);
    align-items: flex-start;
    padding-left:0.5rem;
    box-sizing:border:box;
`;
export const DetailsPanelText = styled(TextSubHeader)`
  text-align: center;
  padding: 0 2rem;
`;

export const DetailsText = styled(Text)`
  text-align: center;
  padding: 0 2rem;
`;


export const Spacer = styled.div<{ height: string }>`
  height: ${(p) => p.height};
  width: 100%;
`;
