import React from "react";
import "./App.css";
import { AppContent } from "./AppContent";
import { BrowserRouter } from "react-router-dom";

function App() {


  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}
export default App;
