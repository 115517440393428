import React from "react";
import { FC, useState } from "react";
import { Aligment, Table } from "venice-ui";
import { ITableHeaderProps } from "../../definitions/ListDefinition";
import { OrgDetails } from "./OrgDetails";
import { useStoreState } from "../../store";


export const OrganizationsList: FC = ({
}) => {
  const { organizations } = useStoreState((state) => state.panel);

  const [orgDetails, toogleOrgDetails] = useState({
    orgId: "",
    show: false,
  });
  const showDetails = (orgID: string) => {
    toogleOrgDetails({
      orgId: orgID,
      show: true,
    });
  };
  const hideOrgModal = () => {
    toogleOrgDetails({
      orgId: "",
      show: false,
    });
  };
  const orgHeaders: ITableHeaderProps[] = [
    {
      name: "Nazwa",
      valueSource: "name",
    },
    {
      name: "Kod",
      valueSource: "nameCode",
    },
    {
      name: "Data utworzenia",
      valueSource: "creationTime",
      date: true,
    },
    {
      name: "Data Wazności",
      valueSource: "activeTime",
      date: true,
    },
    {
      name: "ID",
      valueSource: "id",
    },
  ];

  return (
    <Aligment direction="column" gap={20} align="flex-start" vPadding="16px">
      {organizations.length > 0 ? (
        <Table
          headers={orgHeaders}
          elements={organizations}
          onRowClick={showDetails}
          selectable={false}
          sortable={true}
        ></Table>
      ) : (
        <div>pusto tutaj</div>
      )}
      {orgDetails.show &&
      
        <OrgDetails orgId={orgDetails.orgId} handleClose={hideOrgModal}/>
      }
    </Aligment>
  );
};
