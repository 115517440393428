import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Aligment } from "venice-ui";
import { Colors } from "../../untils/Theme";
import { Account } from "../Account";
import logo from "../../weldingApp_logo_white.svg";
import { useStoreState } from "../../store";
import { HeaderOption } from "../components";

const HeaderWrapper = styled.div`
  height: 4rem;
  background-color: ${Colors.appBlue};
  color: ${Colors.white};
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  position: sticky;
  z-index: 1000;
  top: 0;
`;
const AppName = styled.div`
  font-size: 1.6rem;
  padding: 0 1rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
`;

const Logo = styled.img`
  max-height: 26px;
`;
const Header: FC = () => {

  const { accountType } = useStoreState((state) => state.user.userDetails);

  const navigate = useNavigate();
  return (
    <HeaderWrapper>
      <Aligment justify={"flex-start"}>
        <AppName onClick={() => navigate(`/`)}>
          <Logo src={logo} />
        </AppName>
      </Aligment>
      <Aligment justify={"flex-end"}>
        {accountType==='superAdmin' && 
        <HeaderOption onClick={() => navigate(`/adminpanel`)}>admin panel</HeaderOption>
        }
        <Account />
      </Aligment>
    </HeaderWrapper>
  );
};

export default Header;
