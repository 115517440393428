import React, { FC, ReactText, useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Aligment, Button, Input, TextSmall } from "venice-ui";
import {
  ErrorArea,
  IntputWrapperBig,
  LoginActionLink,
  LoginFooterSection,
  LoginFormSection,
  LoginSubtitle,
  LoginTite,
} from "./Login.styles";
import { LOGIN_MODE, TLoginMode } from "../../helpers/loginHelper";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";

interface ILoginFormProps {
  changeMode: (mode: TLoginMode) => void;
}
export const LoginForm: FC<ILoginFormProps> = ({ changeMode }) => {
  
  
  const [sectionData, updateSectionData] = useState({
    login: "",
    password: "",
    loading: false,
    error: false,
  });
  const navigate = useNavigate();

  const setLoginValue = (field: string, value: string) => {
    updateSectionData({
      ...sectionData,
      [field]: value,
    });
  };

  const auth = getAuth();

  const loginAction = async () => {
    updateSectionData({
      ...sectionData,
      error: false,
      loading: true,
    });
    await signInWithEmailAndPassword(
      auth,
      sectionData.login,
      sectionData.password
    )
      .then((userCredential) => {
        updateSectionData({
          ...sectionData,
          loading: false,
        });
        navigate("/");
      })
      .catch((error: Error) => {
        updateSectionData({
          ...sectionData,
          error: true,
        });
      });
  };
  return (
    <Aligment justify="center" direction="column">
      <LoginTite>{t(dictionary.loginTitle)}</LoginTite>
      <LoginSubtitle>{t(dictionary.loginSubtitle)}</LoginSubtitle>
      <LoginFormSection>
        <IntputWrapperBig>
          <Input
            label={t(dictionary.inputLabelEmail)}
            type="text"
            labelPosition="top"
            value={sectionData.login}
            name="login"
            handleChange={(name: string, value: ReactText) =>
              setLoginValue(name, value as string)
            }
            autoFocus={true}
          />
        </IntputWrapperBig>
        <IntputWrapperBig>
          <Input
            label={t(dictionary.inputLabelPassword)}
            labelPosition="top"
            type="password"
            value={sectionData.password}
            name="password"
            handleChange={(name: string, value: ReactText) =>
              setLoginValue(name, value as string)
            }
            handleSubmit={loginAction}
          />
          <Aligment justify="flex-end">
            <LoginActionLink onClick={() => changeMode(LOGIN_MODE.forgot)}>
              {t(dictionary.dontRemeberPassword)}
            </LoginActionLink>
          </Aligment>
        </IntputWrapperBig>
      </LoginFormSection>
      {
        <>
          {sectionData.error && (
            <LoginFormSection>
              <ErrorArea>
                {t(dictionary.errorWrongLoginOrPassword)}
              </ErrorArea>
            </LoginFormSection>
          )}
          <Button
            text={t(dictionary.actionLogin)}
            onClick={loginAction}
            loader={sectionData.loading}
          />
        </>
      }

      <LoginFooterSection>
        <TextSmall>
          {t(dictionary.actionLogin)}
          <LoginActionLink onClick={() => changeMode(LOGIN_MODE.singin)}>
            {t(dictionary.registerAccount)}
          </LoginActionLink>
        </TextSmall>
      </LoginFooterSection>
    </Aligment>
  );
};
