import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as pdfjs from "pdfjs-dist";
import { apiCall } from "../../untils/apiCall";
import PdfReader from "./PdfReader";
import { getFileUrl } from "../../helpers/fileHelper";
import { objToArray } from "../../helpers/apiHelper";
import {
  getMapElementForPage,
  getPoints,
  getPointsForList,
  setNewPointName,
} from "../../helpers/pdfHelpers";
import {
  clearSelection,
  generateDuplicateElement,
  getDeatilsData,
  isJoint,
  setDotElement,
  setOptionAddElement,
  toggleElementLock,
  updateStateRemoveElement,
  updateStateShowEdit,
  setTriageElement,
  setEdgeElement,
  setOptionMapElement,
  setOptionsSaveElement,
  setCloudElement,
  getElement,
  setShowListPoints,
  setOptionAddReference,
  getReferenceElement,
  setOptionsSaveReferencElement,
  setOptionsSaveSourceElement,
  getMapElement,
} from "./sectionModel";
import { Content } from "../components";
import { ItemList } from "../ItemList";
import { IElementType, IReferenceElement } from "../../definitions/element";
import { ItemDetailPanel } from "../ItemDetailPanel";
import { SectionHeader } from "./SectionHeader";
import {
  emptyDetailsProps,
  emptyModalProps,
  initialSiteProps,
} from "./sectionConst";
import { SectionModals } from "./SectionsModal";
import { LoadingContent } from "../layouts/Loading";
import { Aligment, Card } from "venice-ui";
import { EditSectionModal, ExportModal } from "../Modals";
import { EditSectionData } from "../Modals/EditSectionModal";
import { dictionary } from "../../untils/dictionary";
import { useStoreActions, useStoreState } from "../../store";
import { AddRevisionModal, IFileUpdate } from "../Modals/AddRevision";
import {
  ADDITIONAL_TYPES,
  IAddtionalElements,
  TAdditionalType,
} from "../../definitions/additional";
import { PointModal } from "../PointModal/PointModal";
import { IJointType, getJointFormDefinition } from "../../definitions/joint";
import { ListSectionProps } from "../../types/sectionTypes";
import { pdfMapGenerator, xlsxListGenrator } from "../../helpers/generators";
import { sectionListHeaders } from "../../definitions/ListDefinition";
import { t } from "../../helpers/userHelper";
import { getSectionDetails } from "../../helpers/projectHelper";
import { ifHaveReferences } from "../../helpers/weldHelpers";
const pdfjsLib = require("pdfjs-dist");

pdfjsLib.GlobalWorkerOptions.workerSrc =
  "https://cdn.jsdelivr.net/npm/pdfjs-dist@3.5.141/build/pdf.worker.min.js";

export interface ModalProps {
  show: boolean;
  x?: number;
  y?: number;
  type: string;
  mode: string;
  data?: IElementType;
  // predefineName?: string;
}
export interface IDetailsProps {
  type: string;
  data?: IElementType | IAddtionalElements;
  active: string;
}
export interface ExportModalProps {
  show: boolean;
}
export interface DeleteModalProps {
  show: boolean;
  id: string;
}
export interface DeleteSectionModal {
  show: boolean;
  id: string;
}

export interface SiteProps {
  file: pdfjs.PDFDocumentProxy | null;
  fileUrl: string;
  fileName: string;
  fileDBName: string;
  loading: boolean;
  kks: string;
  drawMode: TAdditionalType;
  editMode: boolean;
  referal: string | undefined;
  name: string;
  modal: ModalProps;
  export: ExportModalProps;
  details: IDetailsProps;
  delete: DeleteModalProps;
  deleteSection: DeleteSectionModal;
  scale: number;
  scaleDirection: string;
}

const Section: FC = () => {
  const navigate = useNavigate();
  const { sectionID, pageID, projectID } = useParams();
  const [editSectionModal, toggleEditSectionModal] = useState(false);
  const [showJointWeld, toogleShowJointWeld] = useState(false);
  const [newRevision, toggleNewRevision] = useState(false);
  const [siteProps, _updateSiteProps] = useState<SiteProps>(initialSiteProps);
  const { orgID, uid } = useStoreState((state) => state.user.userDetails);
  const { elements, references, sections, name, mapElements } = useStoreState(
    (state) => state.projectDetails.projectDetails
  );
  const elementsRef = useRef(elements);
  const {
    addElement,
    updateElement,
    removeElement,
    updateRevision,
    addReference,
    updateReference,
    removeReference,
    clearReferences,
    addMapElements,
    updateMapElements,
    removeMapElements,
    removeSection,
  } = useStoreActions((actions) => actions.projectDetails);

  const myStateRef = React.useRef(siteProps);

  const updateSiteProps = (x: SiteProps) => {
    myStateRef.current = x;
    _updateSiteProps(x);
  };

  const [activeTab, updateActiveTab] = useState("map");
  const tabActionClick = (value: string) => {
    resetSelection();
    updateActiveTab(value);
  };

  const getPageNumber = () => {
    return pageID ? parseInt(pageID) : 1;
  };

  const showAddModal = (x: number, y: number, type: string) => {
    updateSiteProps({
      ...myStateRef.current,
      modal: {
        show: true,
        x: x,
        y: y,
        type: type,
        mode: "add",
        data: undefined,
        // predefineName: newName,
      },
    });
  };
  const resetSelection = () => {
    updateSiteProps(clearSelection(myStateRef.current));
  };
  const hideElementModal = () => {
    updateSiteProps({
      ...myStateRef.current,
      modal: {
        ...emptyModalProps,
      },
    });
  };

  const hideDeleteModal = () => {
    updateSiteProps({
      ...myStateRef.current,
      delete: {
        show: false,
        id: "",
      },
    });
  };

  const hideDeleteSectionModal = () => {
    updateSiteProps({
      ...myStateRef.current,
      deleteSection: {
        show: false,
        id: "",
      },
    });
  };

  const hideExportModal = () => {
    updateSiteProps({
      ...myStateRef.current,
      export: {
        show: false,
      },
    });
  };

  const getDetails = async () => {
    const details = getSectionDetails(sectionID!, sections);
    const fileUrl = await getFileUrl(`${orgID}/${projectID}/${details.file}`);
    const loadingTask = pdfjs.getDocument(fileUrl);
    const pdf = await loadingTask.promise;
    updateSiteProps({
      ...myStateRef.current,
      file: pdf,
      fileUrl: fileUrl,
      fileName: details.fileName,
      loading: false,
      name: details.name,
      fileDBName: details.file,
      kks: details.kks,
    });
  };
  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    elementsRef.current = elements;
  }, [elements]);

  const addPoint = (x: number, y: number, type: string) => {

    if (myStateRef.current.referal) {
      const duplicateElement = generateDuplicateElement(
        myStateRef.current.referal,
        elementsRef.current,
        x,
        y
      );
      createElement(duplicateElement, "duplicate");
    } else {
      showAddModal(x, y, type);
    }
  };
  //ToDo refactor after finish
  const createMapElement = (x: number, y: number, type: string) => {
    if (type === ADDITIONAL_TYPES.dot) {
      createElement(
        setDotElement(x, y, projectID!, sectionID!, getPageNumber())
      );
    }
    if (type === ADDITIONAL_TYPES.triage) {
      createElement(
        setTriageElement(x, y, projectID!, sectionID!, getPageNumber())
      );
    }
    if (type === ADDITIONAL_TYPES.edge) {
      createElement(
        setEdgeElement(
          x,
          y,
          x + 45,
          y + 45,
          projectID!,
          sectionID!,
          getPageNumber()
        )
      );
    }
    if (type === ADDITIONAL_TYPES.cloud) {
      createElement(
        setCloudElement(
          x,
          y,
          x + 100,
          y + 100,
          projectID!,
          sectionID!,
          getPageNumber()
        )
      );
    }
  };

  const showExportModal = async () => {
    updateSiteProps({
      ...myStateRef.current,
      export: {
        show: true,
      },
    });
  };

  const showDeleteSectionModal = async () => {
    updateSiteProps({
      ...myStateRef.current,
      deleteSection: {
        show: true,
        id: sectionID!,
      },
    });
  };

  const showEditModal = (id: string, isReference: boolean) => {
    updateSiteProps(
      updateStateShowEdit(
        myStateRef.current,
        elements,
        references,
        id,
        isReference
      )
    );
  };

  const setReferalPoint = (id: string) => {
    updateSiteProps({
      ...myStateRef.current,
      referal: id,
    });
  };

  const showDetails = (
    id: string,
    isMapElement: boolean,
    isReference: boolean
  ) => {
    const element = isMapElement
      ? getMapElement(id, mapElements)
      : isReference
      ? getReferenceElement(id, references, elements)
      : getElement(id, elements);
    updateSiteProps({
      ...myStateRef.current,
      details: {
        type: element ? element.type : "",
        data: element,
        active: id,
      },
    });
  };

  const showDeleteModal = (id: string) => {
    updateSiteProps({
      ...myStateRef.current,
      delete: {
        show: true,
        id: id,
      },
    });
  };

  const setMode = (drawMode: TAdditionalType) => {
    updateSiteProps({
      ...myStateRef.current,
      drawMode: drawMode,
    });
  };

  const toogleEditMode = (editMode: boolean) => {
    updateSiteProps({
      ...myStateRef.current,
      editMode: editMode,
      details: {
        ...emptyDetailsProps,
      },
    });
  };
  const addReferenceElement = async (sourceId: string) => {
    const settings = setOptionAddReference(
      sourceId,
      siteProps.modal,
      projectID!,
      sectionID!,
      getPageNumber(),
      orgID
    );
    await apiCall("projects/addReference", settings, (response: any) => {
      addReference(response.newReferenceElement);

      updateSiteProps({
        ...myStateRef.current,
        modal: {
          ...emptyModalProps,
        },
        details: {
          ...myStateRef.current.details,
        },
      });
    });
  };

  const createElement = async (
    element: IElementType | IAddtionalElements,
    mode?: string
  ) => {
    const settings = isJoint(element)
      ? setOptionAddElement(
          element as IElementType,
          siteProps.modal,
          projectID!,
          sectionID!,
          getPageNumber(),
          orgID,
          mode!
        )
      : setOptionMapElement(element as IAddtionalElements, orgID, projectID!);

    await apiCall("projects/addElement", settings, (response: any) => {
      const newElement = response.newElement;
      const isReference =
        "sourceElementID" in element! && newElement !== undefined;

      if (isJoint(element)) {
        addElement(response.newElement);
      } else if (isReference) {
        addReference(response.newElement);
      } else {
        addMapElements(response.newElement);
      }
      updateSiteProps({
        ...myStateRef.current,
        details: {
          type: newElement ? newElement.type : "",
          data: newElement,
          active: newElement.id,
        },
        modal: isJoint(element) ? emptyModalProps : myStateRef.current.modal,
      });
    });
  };

  const duplicateElement = (id: string) => {
    createElement(generateDuplicateElement(id, elements), "duplicate");
  };

  //ToDo to update
  const toggleLockElement = async (
    id: string,
    lock: boolean,
    isMapElement: boolean
  ) => {
    await apiCall(
      "projects/toggleLockElement",
      {
        userID: uid,
        projectID: projectID,
        sectionID: sectionID,
        elementID: id,
        lock: lock,
        mapElement: isMapElement,
      },
      (response: any) => {
        updateSiteProps({
          ...myStateRef.current,
          //ToDo to update
          // elements: toggleElementLock(elements, id, lock),
        });
      }
    );
  };

  //ToDo sprawdizc elementID i wyrporostowac ta fukncje
  const saveElement = async (
    element: IElementType | IAddtionalElements,
    isSource: boolean
  ) => {
    const isReference =
      "sourceElementID" in element! && element.sourceElementID !== undefined;

    if (isReference) {
      if (isSource) {
        const sourceElement = getElement(
          element.sourceElementID as string,
          elements
        );
        const sourceOptions = setOptionsSaveSourceElement(
          element as IElementType,
          sourceElement,
          orgID!,
          projectID!
        );
        await apiCall(
          "projects/saveElement",
          sourceOptions,
          (response: any) => {
            updateElement(element);
            updateSiteProps({
              ...myStateRef.current,
              details: {
                type: element.type,
                data: element,
                active: element.id,
              },
            });
          }
        );
      } else {
        const referenceOptions = setOptionsSaveReferencElement(
          element as IReferenceElement,
          uid!,
          projectID!
        );
        await apiCall(
          "projects/saveReference",
          referenceOptions,
          (response: any) => {
            updateReference(element as IReferenceElement);
            updateSiteProps({
              ...myStateRef.current,
              details: {
                type: element.type,
                data: element,
                active: element.id,
              },
            });
          }
        );
      }
    } else {
      const options = isJoint(element)
        ? setOptionsSaveElement(element as IElementType, orgID, projectID!)
        : setOptionMapElement(element as IAddtionalElements, orgID, projectID!);
      await apiCall("projects/saveElement", options, (response: any) => {
        isJoint(element)
          ? updateElement(element as IElementType)
          : updateMapElements(element as IAddtionalElements);
      });
      updateSiteProps({
        ...myStateRef.current,
        details:{
          ...myStateRef.current.details,
          data:element,
        },
        modal: {
          ...emptyModalProps,
        },
      });
    }
  };

  const deleteElement = async (
    id: string,
    isMapElement: boolean,
    isReference: boolean
  ) => {
    await apiCall(
      "projects/removeElement",
      {
        orgID: orgID,
        projectID: projectID,
        elementID: id,
        mapElement: isMapElement,
        isReference: isReference,
      },
      (response: any) => {
        if (isMapElement) {
          removeMapElements(id);
        } else if (isReference) {
          removeReference(id);
        } else {
          if (ifHaveReferences(id, references)) {
            clearReferences(id);
          }
          removeElement(id);
        }
        updateSiteProps(updateStateRemoveElement(myStateRef.current, id));
      }
    );
  };

  const deleteSection = async (id: string) => {
    await apiCall(
      "projects/removeSection",
      {
        orgID: orgID,
        projectID: projectID,
        sectionID: id,
        fileName: myStateRef.current.fileDBName,
      },
      (response: any) => {
        removeSection(id)
        navigate(`/project/${projectID}`);
      }
    );
  };

  const onSubmitEditSection = (newData: EditSectionData) => {
    updateSiteProps({
      ...myStateRef.current,
      name: newData.name,
      kks: newData.kks,
    });

    toggleEditSectionModal(false);
  };

  const onSubmitNewRevision = async (fileUpdate: IFileUpdate) => {
    const fileUrl = await getFileUrl(`${uid}/${projectID}/${fileUpdate.file}`);
    const loadingTask = pdfjs.getDocument(fileUrl);
    const pdf = await loadingTask.promise;

    updateRevision(fileUpdate);
    updateSiteProps({
      ...myStateRef.current,
      file: pdf,
      fileUrl: fileUrl,
      fileName: fileUpdate.fileName,
      fileDBName: fileUpdate.file,

      loading: false,
    });

    toggleNewRevision(false);
  };
  const updateScale = (direciotn: string) => {
    updateSiteProps({
      ...myStateRef.current,
      scaleDirection: direciotn,
    });
  };
  const setScale = (scale: number) => {
    updateSiteProps({
      ...myStateRef.current,
      scaleDirection: "none",
      scale: scale,
    });
  };
  const moreActions = [
    {
      label: t(dictionary.editSection),
      action: () => toggleEditSectionModal(true),
    },

    {
      label: t(dictionary.newRevision),
      action: () => toggleNewRevision(true),
    },
    {
      label: t(dictionary.deleteSection),
      action: () => showDeleteSectionModal(),
    },
  ];

  const exportAction = () => {
    showExportModal();
  };
  const handleExport = () => {
    if (activeTab === "map") {
      pdfMapGenerator(
        myStateRef.current.fileUrl,
        myStateRef.current.fileName,
        elements,
        mapElements
      );
    } else {
      xlsxListGenrator(
        //ToDo add referenscja toogle
        getPointsForList(elements, sectionID!, references, showJointWeld),
        sectionListHeaders,
        myStateRef.current.name
      );
    }
    hideExportModal();
  };

  if (siteProps.loading) {
    return <LoadingContent />;
  }
  return (
    <Content>
      <Card
        handleClick={exportAction}
        actionLabel={t(dictionary.export)}
        title={`${name}/${siteProps.name}`}
        height={"calc(100vh - 6rem)"}
        handleBack={() => navigate(`/project/${projectID}`)}
        options={moreActions}
        shadow={false}
      >
        {myStateRef.current.modal.show && (
          <PointModal
            handleCancel={hideElementModal}
            handleSave={saveElement}
            handleDelete={deleteElement}
            handleAdd={createElement}
            handleAddReference={addReferenceElement}
            elementID={siteProps.details.active}
            element={getJointFormDefinition(siteProps.modal.data as IJointType)}
            modalMode={siteProps.modal.mode}
            predefineName={
              siteProps.modal.mode === "add"
                ? setNewPointName(
                    elements,
                    myStateRef.current.referal ?? myStateRef.current.referal
                  )
                : ""
            }
          />
        )}
        <>
          <SectionModals
            siteProps={myStateRef.current}
            deleteSection={deleteSection}
            hideDeleteModal={hideDeleteModal}
            deleteElement={deleteElement}
            hideDeleteSectionModal={hideDeleteSectionModal}
          />
          {myStateRef.current.export.show && (
            <ExportModal
              handleCancel={hideExportModal}
              handleConfirm={handleExport}
              weldmap={activeTab === "map"}
              sectionLevel={true}
              name={myStateRef.current.name}
            />
          )}
          <Aligment align="stretch" direction="row">
            <Aligment
              direction="column"
              width={activeTab === "map" ? "calc( 100% - 240px)" : "100%"}
              justify="flex-start"
            >
              <SectionHeader
                activeTab={activeTab}
                pageCount={myStateRef.current.file!.numPages}
                referal={myStateRef.current.referal}
                drawMode={myStateRef.current.drawMode}
                editMode={myStateRef.current.editMode}
                scale={myStateRef.current.scale}
                showJointWeld={showJointWeld}
                setActiveTab={tabActionClick}
                activePage={getPageNumber()}
                setReferal={setReferalPoint}
                resetSelection={resetSelection}
                setMode={setMode}
                toogleEditMode={toogleEditMode}
                updateScale={updateScale}
                toogleShowJointWeld={toogleShowJointWeld}
              />
              {editSectionModal && (
                <EditSectionModal
                  onClose={() => toggleEditSectionModal(false)}
                  onConfirm={onSubmitEditSection}
                  dataSet={{
                    sectionID: sectionID!,
                    projectID: projectID!,
                    name: myStateRef.current.name,
                    kks: myStateRef.current.kks,
                    show: true,
                  }}
                />
              )}
              {newRevision && (
                <AddRevisionModal
                  onClose={() => toggleNewRevision(false)}
                  onConfirm={onSubmitNewRevision}
                  dataSet={{
                    sectionID: sectionID!,
                    projectID: projectID!,
                    oldFileName: myStateRef.current.fileDBName,
                    file: undefined,
                    show: true,
                  }}
                />
              )}

              {activeTab === "map" && (
                <Aligment align="stretch">
                  <PdfReader
                    pdf={myStateRef.current.file!}
                    pageNumber={getPageNumber()}
                    addElementAction={addPoint}
                    addMapElement={createMapElement}
                    points={getPoints(
                      myStateRef.current,
                      elements,
                      references,
                      getPageNumber(),
                      sectionID!
                    )}
                    mapElements={getMapElementForPage(
                      mapElements,
                      getPageNumber(),
                      sectionID!
                    )}
                    activeItem={myStateRef.current.details.active}
                    showDetails={showDetails}
                    saveChanges={saveElement}
                    drawMode={myStateRef.current.drawMode}
                    editMode={myStateRef.current.editMode}
                    setScale={setScale}
                    scaleDirection={myStateRef.current.scaleDirection}
                  />
                </Aligment>
              )}
              {activeTab === "list" && (
                <ItemList showJointWeld={showJointWeld} />
              )}
            </Aligment>
            {activeTab === "map" && (
              <ItemDetailPanel
                element={getDeatilsData(
                  myStateRef.current.details.type,
                  myStateRef.current.details.data
                )}
                type={myStateRef.current.details.type}
                handleEdit={showEditModal}
                handleDuplicate={duplicateElement}
                handelSetReference={setReferalPoint}
                handleRemove={showDeleteModal}
                handleLockChange={toggleLockElement}
                handleSaveChanges={saveElement}
                editMode={myStateRef.current.editMode}
                additional={mapElements}
                showDetails={showDetails}
              />
            )}
          </Aligment>
        </>
      </Card>
    </Content>
  );
};

export default Section;
