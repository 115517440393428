export const Colors = {
  background: '#d9d9d9',
  darkGray: '#353535',
  white: '#ffffff',
  preWhite:'#8b8b8b',
  oliveGreen: '#3c6e71',
  oliveGreenTransparent:'rgba(60,110,113,0.1)',
  seaBlue:'#3b90cb',
  red: '#ff0000',
  backgroundGray: '#d5d5d5',
  tileGray: '#f5f5f5',
  appBlue: '#202C42',
  appBlueAccent:'#e6f0f7',
  green: '#71e41d',

  seaBlueTransparent:'rgba(59,144,203,0.2)',
  halfTransparent: 'rgba(86,86,86,0.9)',
  whiteTransparent: 'rgba(0,0,0,0.7)',

  text: '#333333',
  disableBackground: '#cccccc',
  disableText: "#979797",
}