import React, { FC } from "react";
import { Card, Aligment, Text, Loader } from "venice-ui";
import { PAYMENT_MODE, TInfoMode } from "../../../helpers/settingsHelper";
import { dictionary } from "../../../untils/dictionary";
import { t } from "../../../helpers/userHelper";

interface IPaymentLoader {
  mode: TInfoMode;
}
export const PaymentLoader: FC<IPaymentLoader> = ({ mode }) => {
  
  
  return (
    <Card>
      <Aligment
        align="center"
        gap={20}
        justify="center"
        vPadding="24px"
        hPadding="24px"
      >
        {mode === PAYMENT_MODE.success && (
          <Text>{t(dictionary.paymentLoader)}</Text>
        )}
        <Loader size="medium" />
      </Aligment>
    </Card>
  );
};
