export const getLineLength = (target_x: number, target_y: number): number => {
  const a = Math.abs(target_x - 1);
  const b = Math.abs(target_y - 1);
  return Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
};

export const getLineArc = (target_x: number, target_y: number): number => {
  const deltaX = target_x;
  const deltaY = target_y;
  const angleRadians = Math.atan2(deltaX, deltaY);
  const angleDegrees = -angleRadians * (180 / Math.PI);
  return angleDegrees;
};

export const squareHeight = 3;
export const roundHeight = 5;

export const squareWidth = 9;
export const roundWidth = 5;

export const getLabelCordX = (x: number, round: boolean): number => {
  const diff = ((round ? roundWidth : squareWidth) / 2) * 10;
  return x - diff;
};

export const getLabelCordY = (y: number, round: boolean): number => {
  const diff = ((round ? roundHeight : squareHeight) / 2) * 10;
  return y - diff;
};
