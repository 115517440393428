import React, { FC, useState } from "react";
import { Form, Modal } from "venice-ui";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
import { getContractName } from "../../helpers/projectHelper";
import { useStoreActions, useStoreState } from "../../store";
import { apiCall } from "../../untils/apiCall";
import { checkIfUserHaveContract } from "../../helpers/organizationHelper";

interface IContractModal {
  handleClose: () => void;
  contractID?: string;
}
export const ContractModal: FC<IContractModal> = ({
  handleClose,
  contractID,
}) => {
  const { uid, accountType, contracts, orgID } = useStoreState(
    (state) => state.user.userDetails
  );
  const [contractName, setContractName] = useState(
    contractID ? getContractName(contractID, contracts) : ""
  );

  const { addContract, editContractName } = useStoreActions(
    (actions) => actions.organization
  );
  const { addContractToUser,editContractName: editNameForUser } = useStoreActions((actions) => actions.user);

  const updateName = (field: string, value: string | number) => {
    setContractName(value as string);
  };

  const handleConfirm = async () => {
    if (contractID) {
      const options = {
        name: contractName.trim(),
        orgID: orgID,
        contractID: contractID,
      };
      await apiCall(
        "organization/updateContractName",
        options,
        (response: any) => {
          editContractName({ contractID, name: contractName.trim() });
          if (checkIfUserHaveContract(contractID, contracts)) {
            editNameForUser({ contractID, name: contractName.trim() });
          }
          handleClose();
        }
      );
    } else {
      const options = {
        contract: {
          name: contractName.trim(),
        },
        orgID: orgID,
        userID: uid,
      };
      await apiCall(
        "organization/createNewContract",
        options,
        (response: any) => {
          const newContract = {
            id: response.newContarctID,
            name: contractName,
            members: response.members,
          };

          addContract(newContract);
          addContractToUser({ id: newContract.id, name: contractName });
          handleClose();
        }
      );
    }
  };
  const newProjectForm = {
    action: updateName,
    data: [
      {
        type: "text",
        label: t(dictionary.contractName),
        value: contractName,
        name: "name",
        autofocus: true,
        submit: handleConfirm,
      },
    ],
  };

  return (
    <Modal
      title={
        contractID
          ? t(dictionary.editContractName)
          : t(dictionary.createNewContract)
      }
      handleClose={handleClose}
      labelClose={t(dictionary.close)}
      handleConfirm={handleConfirm}
      labelConfirm={contractID ?t(dictionary.save) : t(dictionary.add)}
      submitDisabled={contractName === ""}
    >
      <Form formData={newProjectForm} />
    </Modal>
  );
};
