import { IOrgContract, IOrgMember } from "../../store/interface/organization";

export const getMemberEmail = (members: IOrgMember[], memberID: String) => {
  let memberEmail = "";
  const found = members.find((_memeber) => _memeber.id === memberID);
  if (found) {
    memberEmail = found.email;
  }
  return memberEmail;
};

export const checkUserLimit = (planKey: string, membersCount: number) => {
  let limit = 0;
  switch (planKey) {
    case "standard":
      limit = 5;
      break;
    default:
      limit = 1;
      break;
  }
  return membersCount <= limit;
};

