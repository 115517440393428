import axios from "axios";
import { apiURL, appURL } from "../untils/const";
import { add } from "date-fns";

export const createUser = async (
  email: string,
  userID: string,
  productId: string,
  orgName: string
) => {
  const options = {
    email: email,
    orgName: orgName,
    userID: userID,
    productId: productId,
    creationTime: Date.now(),
    activeTime: add(Date.now(), {
      years: 1,
    }).valueOf(),
  };
  let status = false;
  await axios
    .post(`${apiURL}/register/createUserAndOrgaznization`, {
      ...options,
    })
    .then((response) => {
      localStorage.removeItem("registerFlow");
      window.location.href = response.data.session.url;
    })
    .catch(function (error) {
      console.log(error);
    });
  return status;
};

export const joinUser = async (
  email: string,
  userID: string,
  invitationID:string
  )=>{
    const options ={
      email: email,
      userID: userID,
      invitationID:invitationID
    }
    let status = false;
  await axios
    .post(`${apiURL}/register/joinUserFronInvitation`, {
      ...options,
    })
    .then((response) => {
      localStorage.removeItem("registerFlow");
      window.location.href = appURL;
    })
    .catch(function (error) {
      console.log(error);
    });
  return status;

  }


export interface ILimitationProps {
  projects: number;
  sections: number;
  points: number;
}

export const planSetting = {
  free: {
    projects: 1,
    sections: 1,
    points: 20,
  },
  basic: {
    projects: 3,
    sections: 3,
    points: 200,
  },
  premium: {
    projects: 0,
    sections: 0,
    points: 0,
  },
};

interface IResponseTagsProps {
  type: string;
  lang: string;
}

export interface IResponsePricingProps {
  id: string;
  name: string;
  priceId: string;
  price: number;
  currency: string;
  tags: IResponseTagsProps;
}

export const getLang = (detectedLang: string) => {
  console.log('getLang',detectedLang)
  let lang = "en";
  if (detectedLang) {
    if (detectedLang === "pl" || detectedLang === "pl-PL") {
      lang = "pl";
    }
  }
  return lang;
};

interface ITLangObj {
  pl: string;
  en: string;
}
export const t = (obj: ITLangObj) => {
  const lang = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : navigator.language;

  return obj[lang as keyof typeof obj];
};
