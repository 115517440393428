import styled from "styled-components";
import { Colors } from "../untils/Theme";

export const AppWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  font-size: 1.6rem;
  background-color: ${Colors.backgroundGray};
  flex-direction: column;
`;
export const ContentArea = styled.div`
  width: 100%;
  // height: calc(100vh - 4rem);
  display: flex;
  font-size: 1.6rem;
  flex-direction: column;
`;



export const Content = styled.div`
  width: 100%;
  padding:1rem;
`;
interface IContentWrapper {
height:string}
export const ContentWrapper = styled.div<IContentWrapper>`
  height:${p => p.height};
  overflow:auto;
  width:100%;
`


export const ExportTileWrapper = styled.div`
  padding-bottom: 5px;
`

export const Link = styled.a`
  text-decoration:underline;
  color:#333333;
`
export const HeaderOption = styled.div`
  cursor:pointer;
  margin-right:20px;
`

export const ListWrapper = styled.div`
  display:flex;
  flex-direction:column;
  width:100%;
  .item-list:nth-child(even){
    background-color:#f3f3f3;
  }
`
export const ItemElement = styled.div`
display:flex;
width:100%;
justify-content: space-between;
    align-items: center;
    padding: 0.4rem 0;
`