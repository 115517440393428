import React, { FC, useEffect, useState } from "react";
import { Content } from "../components";
import { apiCall } from "../../untils/apiCall";
import { PaymentInfo, PaymentLoader } from "./Partial";
import { PAYMENT_MODE } from "../../helpers/settingsHelper";
import { useStoreActions, useStoreState } from "../../store";

export const SuccessPayment: FC = () => {
  const { userDetails } = useStoreState((state) => state.user);

  const setUserDetails = useStoreActions(
    (actions) => actions.user.setUserDetails
  );
  const [loading, toogleLoading] = useState(true);
  console.log("------------------");

  const setDetials = async (sessionID: string, orgID: string) => {    
    const options = {
      sessionID: sessionID,
      orgID: orgID,
    };

    await apiCall("payments/setPaymentsDetails", options, (response: any) => {
      console.log('api')
      setUserDetails({
        ...userDetails,
        orgStatus: response.orgStatus,
      });
      toogleLoading(false);
    });
  };

  useEffect(() => {
    console.log('effect')
    setDetials(userDetails.orgSesionID, userDetails.orgID);
  }, []);



  return (
    <Content>
      {loading ? (
        <PaymentLoader mode={PAYMENT_MODE.success} />
      ) : (
        <PaymentInfo mode={PAYMENT_MODE.success} />
      )}
    </Content>
  );
};
