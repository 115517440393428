import React, {  } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AppWrapper, ContentArea } from "../components/components";
import Header from "../components/layouts/Header";
import { LoadingPage, GlobalLoadingPage } from "../components/layouts/Loading";
import { ErrorPayment } from "../components/Payments";
import { PAYMENT_MODE } from "../helpers/settingsHelper";
import { useStoreState } from "../store";
import { ACCOUNT_TYPE } from "../store/interface/user";

export function RequireAuth({ children }: { children: JSX.Element }) {
  const { uid, loading, orgStatus, accountType } = useStoreState(
    (state) => state.user.userDetails
  );
  let location = useLocation();
  if (loading) {
    return <LoadingPage />;
  }
  if (!uid) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <AppWrapper>
      <Header />
      <ContentArea>
        {accountType === "normal" && !orgStatus ? (
          <ErrorPayment mode={PAYMENT_MODE.missed} />
        ) : (
          children
        )}
      </ContentArea>
    </AppWrapper>
  );
}

export function PaymentFlow({ children }: { children: JSX.Element }) {
  const { uid, loading } = useStoreState((state) => state.user.userDetails);

  let location = useLocation();
  if (loading) {
    return <LoadingPage />;
  }

  if (!uid) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return (
    <AppWrapper>
      <Header />
      <ContentArea>{children}</ContentArea>
    </AppWrapper>
  );
}

export function AutoAuth({ children }: { children: JSX.Element }) {
  const { uid, loading } = useStoreState((state) => state.user.userDetails);

  let location = useLocation();
  if (loading) {
    return <GlobalLoadingPage />;
  }
  if (uid) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
}

export function SuperAdmin({ children }: { children: JSX.Element }) {
  const { uid, loading, orgStatus, accountType } = useStoreState(
    (state) => state.user.userDetails
  );
  let location = useLocation();

  if (loading) {
    return <LoadingPage />;
  }
  if (!uid) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (
    accountType === ACCOUNT_TYPE.normal
  ) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return (
    <AppWrapper>
      <Header />
      <ContentArea>
        {!orgStatus ? (
          <ErrorPayment mode={PAYMENT_MODE.missed} />
        ) : (
          children
        )}
      </ContentArea>
    </AppWrapper>
  );
}
