import React, { FC, useEffect, useState } from "react";
import { Content } from "../components";
import { apiCall } from "../../untils/apiCall";
import { PaymentInfo, PaymentLoader } from "./Partial";
import { TInfoMode } from "../../helpers/settingsHelper";
import { useStoreState } from "../../store";

interface IErrorPayment {
  mode: TInfoMode;
}

export const ErrorPayment: FC<IErrorPayment> = ({ mode }) => {
  const { orgRole } = useStoreState(
    (state) => state.user.userDetails
  );

  const [pageData, setPageData] = useState({
    loading: true,
    sessionLink: "",
  });

  const getCustomerPortal = async () => {
    if (orgRole === "admin") {
      const options = {
        // ToDo dodac z bazy
        sessionID: `stripeSession`,
      };
      await apiCall("payments/getCustomerPortal", options, (response: any) => {
        setPageData({
          loading: false,
          sessionLink: response.portalSession.url,
        });
      });
    }
  };

  useEffect(() => {
    getCustomerPortal();
  }, []);

  return (
    <Content>
      {pageData.loading ? (
        <PaymentLoader mode={mode} />
      ) : (
        <PaymentInfo sessionLink={pageData.sessionLink} mode={mode} />
      )}
    </Content>
  );
};
