import {
  IAddtionalDot,
  IAddtionalEdge,
  IAddtionalTriage,
  IAddtionalElements,
  IAddtionalCloud,
} from "../../definitions/additional";
import {
  IElementType,
  IExtendElementType,
  IReferenceElement,
} from "../../definitions/element";
import { IJointType, getJointDefinition } from "../../definitions/joint";
import { setNewPointName } from "../../helpers/pdfHelpers";
import { ListSectionProps } from "../../types/sectionTypes";
import {  ModalProps, SiteProps } from "./Section";
import {
  emptyDetailsProps,
  emptyElement,
  emptyMapElement,
  emptyModalProps,
} from "./sectionConst";

export const isJoint = (element: IElementType | IAddtionalElements) => {
  return element.type === "joint";
};

export const isReference = (element: IElementType) => {
  return !!element.sourceElementID;
};

export const getElement = (
  id: string,
  elements: IElementType[]
): IElementType => {
  const selectedElement =
    elements.find((item) => item.id === id) || emptyElement;
  return selectedElement;
};

export const getMapElement = (
  id: string,
  elements: IAddtionalElements[]
): IAddtionalElements => {
  const selectedElement =
    elements.find((item) => item.id === id) || emptyMapElement;
  return selectedElement;
};
export const getReferenceElement = (
  id: string,
  references: IReferenceElement[],
  elements: IElementType[]
): IElementType => {
  const ref = references.find((item) => item.id === id);
  if (ref) {
    const source = elements.find((item) => item.id === ref.sourceElementID);
    if (source) {
      return {
        ...source,
        ...ref,
      };
    }
  }
  return emptyElement;
};

export const serializeNewElement = (
  element: IElementType,
  info: ModalProps,
  sectionID: string,
  pageNumber: number,
  mode: string
): IElementType => {
  const isDuplicate = mode === "duplicate";
  element.name = encodeURIComponent(element.name.trim());
  return {
    ...element,
    x: isDuplicate ? element.x : info.x!,
    y: isDuplicate ? element.y : info.y!,
    type: isDuplicate ? element.type : info.type,
    sectionID: sectionID,
    page: pageNumber,
    label_x: 30,
    label_y: 30,
    lock: false,
    createdDate: Date.now(),
  };
};

interface ISetOptionAddElement {
  element: IElementType;
  orgID: string;
  projectID: string;
}
export const setOptionAddElement = (
  element: IElementType,
  info: ModalProps,
  projectID: string,
  sectionID: string,
  pageNumber: number,
  orgID: string,
  mode: string
): ISetOptionAddElement => {
  return {
    element: serializeNewElement(element, info, sectionID, pageNumber, mode),
    orgID: orgID,
    projectID: projectID,
  };
};

export const serializeNewReferenceElement = (
  sourceId: string,
  info: ModalProps,
  sectionID: string,
  pageNumber: number
): IReferenceElement => {
  return {
    id: "",
    sourceElementID: sourceId,
    sectionID: sectionID,
    page: pageNumber,
    x: info.x!,
    y: info.y!,
    label_x: 30,
    label_y: 30,
    lock: false,
    createdDate: Date.now(),
  };
};

interface ISetOptionAddRefernceElement {
  referenceElement: IReferenceElement;
  orgID: string;
  projectID: string;
}
export const setOptionAddReference = (
  sourceId: string,
  info: ModalProps,
  projectID: string,
  sectionID: string,
  pageNumber: number,
  orgID: string
): ISetOptionAddRefernceElement => {
  return {
    referenceElement: serializeNewReferenceElement(
      sourceId,
      info,
      sectionID,
      pageNumber
    ),
    orgID: orgID,
    projectID: projectID,
  };
};

export const serializeSaveElement = (element: IJointType) => {
  return {
    name: encodeURIComponent(element.name.trim()),
    id: element.id,
    sectionID: element.sectionID,
    page: element.page,
    x: element.x,
    y: element.y,
    label_x: element.label_x,
    label_y: element.label_y,
    type: element.type,
    lock: element.lock,
    joint_type: element.joint_type,
    element_type: element.element_type,
    diameter_1: element.diameter_1,
    diameter_2: element.diameter_2,
    thickness_1: element.thickness_1,
    thickness_2: element.thickness_2,
    material_1: element.material_1,
    material_2: element.material_2,
    createdDate: element.createdDate
  };
};

export const serializeSaveSourceElement = (
  element: IJointType,
  source: IJointType
) => {
  return {
    name: encodeURIComponent(element.name.trim()),
    id: source.id,
    sectionID: source.sectionID,
    page: source.page,
    x: source.x,
    y: source.y,
    label_x: source.label_x,
    label_y: source.label_y,
    type: element.type,
    lock: element.lock,
    joint_type: element.joint_type,
    element_type: element.element_type,
    diameter_1: element.diameter_1,
    diameter_2: element.diameter_2,
    thickness_1: element.thickness_1,
    thickness_2: element.thickness_2,
    material_1: element.material_1,
    material_2: element.material_2,
  };
};

export const setOptionsSaveElement = (
  element: IElementType,
  orgID: string,
  projectID: string,
) => {
  return {
    element: serializeSaveElement(element as IJointType),
    orgID,
    projectID,
  };
};
export const setOptionsSaveSourceElement = (
  element: IElementType,
  source: IElementType,
  orgID: string,
  projectID: string
) => {
  return {
    element: serializeSaveSourceElement(
      element as IJointType,
      source as IJointType
    ),
    orgID,
    projectID,
  };
};

export const serializeSaveReference = (element: IJointType) => {
  return {
    id: element.id,
    sectionID: element.sectionID,
    page: element.page,
    x: element.x,
    y: element.y,
    label_x: element.label_x,
    label_y: element.label_y,
    lock: element.lock,
    sourceElementID: element.sourceElementID,
  };
};
export const setOptionsSaveReferencElement = (
  element: IReferenceElement,
  userID: string,
  projectID: string
) => {
  return {
    element: serializeSaveReference(element as IJointType),
    userID,
    projectID,
  };
};





// // /ToDo to delete -not yet?
// export const updateStateShowDetails = (
//   elements: IElementType[],
//   additional: IAddtionalElements[],
//   references: IReferenceElement[],
//   id: string,
//   isMapElement: boolean,
//   isReference: boolean
// ): IDetailsProps => {
//   const element = isMapElement
//     ? getMapElement(id,additional)
//     : isReference
//     ? getReferenceElement(id, references, elements)
//     : getElement(id, elements);

//   return {
//     type: element ? element.type : "",
//     data: element,
//     active: id,
//   };
// };

export const updateStateShowEdit = (
  currentState: SiteProps,
  elements: IElementType[],
  references: IReferenceElement[],
  id: string,
  isReference: boolean
): SiteProps => {
  const element = isReference
    ? getReferenceElement(id, references, elements)
    : getElement(id, elements);
  return {
    ...currentState,
    modal: {
      show: true,
      x: element.x,
      y: element.y,
      type: element.type,
      mode: "edit",
      data: element,
    },
    details: {
      ...currentState.details,
    },
  };
};


export const updateStateRemoveElement = (
  currentState: SiteProps,
  id: string,
): SiteProps => {
  return {
    ...currentState,
    referal: currentState.referal === id ? "" : currentState.referal,
    modal: {
      ...emptyModalProps,
    },
    details: {
      ...emptyDetailsProps,
    },
    delete: {
      show: false,
      id: "",
    },
  };
};

// export const updateAllReferences = (
//   references: IReferenceElement[],
//   element: IElementType
// ): IReferenceElement[] => {
//   return references as IReferenceElement[];
// };

// export const updateStateAfterSaveElement = (
//   currentState: SiteProps,
//   element: IElementType | IAddtionalElements
// ): SiteProps => {
//   return {
//     ...currentState,
//     additional: isJoint(element)
//       ? currentState.additional
//       : updateMapElements(
//           currentState.additional,
//           element as IAddtionalElements
//         ),
//     elements: isJoint(element)
//       ? updateElements(currentState.elements, element as IElementType)
//       : currentState.elements,
//     modal: {
//       ...emptyModalProps,
//     },
//     details: {
//       type: element.type,
//       data: element,
//       active: element.id,
//     },
//   };
// };


export const updateElements = (
  allElements: IElementType[],
  element: IElementType
) => {
  const id = element.sourceElementID ? element.sourceElementID : element.id;
  const index = allElements.findIndex((item) => item.id === id);
  if (index !== -1) {
    const sourceElement: IJointType = element as IJointType;
    const newElement = allElements[index] as IJointType;
    newElement.name = encodeURIComponent(element.name.trim());
    newElement.type = sourceElement.type;
    newElement.joint_type = sourceElement.joint_type;
    newElement.element_type = sourceElement.element_type;
    newElement.diameter_1 = sourceElement.diameter_1;
    newElement.diameter_2 = sourceElement.diameter_2;
    newElement.thickness_1 = sourceElement.thickness_1;
    newElement.thickness_2 = sourceElement.thickness_2;
    newElement.material_1 = sourceElement.material_1;
    newElement.material_2 = sourceElement.material_2;
  }
  return allElements;
};


export const updateRemoveReference = (
  allElements: IReferenceElement[],
  id: string
) => {
  return allElements.filter((element) => element.id !== id);
};
export const updateRemoveReferenceSource = (
  allElements: IReferenceElement[],
  id: string
) => {
  return allElements.filter((element) => element.sourceElementID !== id);
};

export const getItemName = (allElements: IElementType[], id: string) => {
  return getElement(id, allElements).name;
};

export const toggleElementLock = (
  allElements: IElementType[],
  id: string,
  lock: boolean
): IElementType[] => {
  const updated = [...allElements];
  updated.forEach((element) => {
    if (element.id === id) {
      element.lock = lock;
    }
  });
  return updated;
};

export const generateDuplicateElement = (
  sourcePointId: string,
  elements: IElementType[],
  x?: number,
  y?: number
): IElementType => {
  const selectedElement = getElement(sourcePointId, elements);
  return {
    ...selectedElement,
    x: x ? x : selectedElement.x + 60,
    y: y ? y : selectedElement.y - 20,
    lock: false,
    createdDate: Date.now(),
    name: setNewPointName(elements, selectedElement.name),
    id: "",
  };
};

export const clearSelection = (currentState: SiteProps): SiteProps => {
  return {
    ...currentState,
    referal: "",
    details: {
      ...emptyDetailsProps,
    },
  };
};
//ToDo refacto ? join with others
export const setDotElement = (
  x: number,
  y: number,
  projectID: string,
  sectionID: string,
  page: number
): IAddtionalElements => {
  return {
    id: "",
    type: "dot",
    sectionID,
    page,
    x,
    y,
    lock: false,
    size: 20,
    rotation: 0,
    height: 0,
    end_x: 0,
    end_y: 0,
  };
};

export const setTriageElement = (
  x: number,
  y: number,
  projectID: string,
  sectionID: string,
  page: number
): IAddtionalElements => {
  return {
    id: "",
    type: "triage",
    sectionID,
    page,
    x,
    y,
    lock: false,
    size: 20,
    rotation: 0,
    height: 0,
    end_x: 0,
    end_y: 0,
  };
};

export const setEdgeElement = (
  x: number,
  y: number,
  end_x: number,
  end_y: number,
  projectID: string,
  sectionID: string,
  page: number
): IAddtionalElements => {
  return {
    id: "",
    type: "edge",
    sectionID,
    page,
    x,
    y,
    end_x,
    end_y,
    lock: false,
    height: 4,
    size: 0,
    rotation: 0,
  };
};

export const setCloudElement = (
  x: number,
  y: number,
  end_x: number,
  end_y: number,
  projectID: string,
  sectionID: string,
  page: number
): IAddtionalElements => {
  return {
    id: "",
    type: "cloud",
    sectionID,
    page,
    x,
    y,
    end_x,
    end_y,
    lock: false,
    height: 0,
    size: 0,
    rotation: 0,
  };
};

export const updateMapElements = (
  allElements: IAddtionalElements[],
  element: IAddtionalElements
) => {
  const index = allElements.findIndex((item) => item.id === element.id);
  allElements[index] = element;
  return allElements;
};

export const getDeatilsData = (
  detailsType: string,
  detailsData?: IElementType | IAddtionalElements
): IElementType | IAddtionalElements | undefined => {
  if (detailsType !== "") {
    if (detailsType !== "joint") {
      return detailsData;
    } else {
      return getJointDefinition(detailsData as IJointType);
    }
  }
};
//
export const setOptionMapElement = (
  element: IAddtionalElements,
  orgID: string,
  projectID: string
) => {
  let mapElement = {};
  switch (element.type) {
    case "dot":
      mapElement = serializeDotElement(element);
      break;
    case "triage":
      mapElement = serializeTriageElement(element);
      break;
    case "edge":
      mapElement = serializeEdgeElement(element);
      break;
    case "cloud":
      mapElement = serializeCloudElement(element);
      break;
  }

  return { element: mapElement, orgID, projectID };
};
// ToDo refacort
export const serializeDotElement = (
  element: IAddtionalElements
): IAddtionalDot => {
  return {
    type: "dot",
    id: element.id,
    sectionID: element.sectionID,
    page: element.page,
    x: element.x,
    y: element.y,
    lock: false,
    size: element.size,
  };
};

//ToDo refactor
export const serializeTriageElement = (
  element: IAddtionalElements
): IAddtionalTriage => {
  return {
    type: "triage",
    id: element.id,
    sectionID: element.sectionID,
    page: element.page,
    x: element.x,
    y: element.y,
    lock: false,
    size: element.size,
    rotation: element.rotation,
  };
};

//ToDo refactor
export const serializeEdgeElement = (
  element: IAddtionalElements
): IAddtionalEdge => {
  return {
    type: "edge",
    id: element.id,
    sectionID: element.sectionID,
    page: element.page,
    x: element.x,
    y: element.y,
    lock: false,
    end_x: element.end_x,
    end_y: element.end_y,
    height: element.height,
  };
};

export const serializeCloudElement = (
  element: IAddtionalElements
): IAddtionalCloud => {
  return {
    type: "cloud",
    id: element.id,
    sectionID: element.sectionID,
    page: element.page,
    x: element.x,
    y: element.y,
    lock: false,
    end_x: element.end_x,
    end_y: element.end_y,
    rotation: element.rotation,
  };
};
export const generatePoint = (
  element: IJointType,
  section: ListSectionProps
) => {
  return {
    kks: section.kks,
    ...element,
  };
};
export const setShowListPoints = (
  elements: IElementType[],
  sections: ListSectionProps[]
) => {
  const points: IExtendElementType[] = [];
  if(sections.length === 0 || elements.length ===0){
    return points
  }
  elements.forEach((item) => {
    const newPoint = { ...item };
    const sectionDetails = sections.find(
      (section) => section.id === newPoint.sectionID
    );
    if (sectionDetails) {
      points.push(generatePoint(item as IJointType, sectionDetails));
    }
  });
  return points;
};
