import React, { FC } from "react";
import { Aligment, Table } from "venice-ui";
import { ITableHeaderProps } from "../../definitions/ListDefinition";
import { useStoreState } from "../../store";

export const UsersList: FC = () => {
  const { users } = useStoreState((state) => state.panel);
  const orgHeaders: ITableHeaderProps[] = [
    {
      name: "email",
      valueSource: "email",
    },
    {
      name: "typ konta",
      valueSource: "accountType",
    },
    {
      name: "Status płatności",
      valueSource: "paymentStatus",
    },
    {
      name: "ID produktu",
      valueSource: "productId",
    },
    {
      name: "ID",
      valueSource: "id",
    },
  ];

  return (
    <Aligment direction="column" gap={20} align="flex-start" vPadding="16px">
      {users.length > 0 ? (
        <Table
          headers={orgHeaders}
          elements={users}
          // onRowClick={showDetails}
          selectable={false}
          sortable={true}
        ></Table>
      ) : (
        <div>pusto tutaj</div>
      )}
    </Aligment>
  );
};
