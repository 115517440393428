export const apiURL = process.env.REACT_APP_API_URL;
export const appURL:string = process.env.REACT_APP_APP_URL || '';
export const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE!)

export const alphabet = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];
