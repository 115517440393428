import styled from "styled-components";
import { TextHeader,  Text } from "venice-ui";
import { Colors } from "../../untils/Theme";

export const LoginTile = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  max-height: 100%;
  overflow: hidden;
  flex-direction: column;
  position: relative;
  height: auto;
  max-width: 360px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23) !important;
`;

export const LoginTite = styled(TextHeader)`
  font-weight: bold !important;
  padding-bottom: 10px;
`;

export const LoginSubtitle = styled(Text)`
  padding-bottom: 20px;
`;

export const LoginFormSection = styled.div`
  padding: 10px;
  width: 100%;
`;

export const LoginFooterSection = styled.div`
  padding-top: 50px;
`;


export const LoginActionLink = styled.span`
  padding-left:5px;
  padding-top:4px;
  font-size: 1.4rem;
  color: ${Colors.seaBlue};
  cursor: pointer;
`;

export const ErrorArea = styled.div`
  box-sizing:border-box;
  color: #a10404;
  width: 100%;
  background-color: #ffcacf;
  padding:10px;
  text-align:center;
`

export const WelcomeLogo = styled.img`
  width:100%;
  max-width:200px;
  margin-bottom:20px;
`

//ToDo to delete
interface IPlanBoxProps{
  active: boolean
}
export const PlanBox = styled.div<IPlanBoxProps>`
  background-color:${p=> p.active? '#73acd1' : '#ffffff'};
  color:${p=> p.active? '#ffffff' : '##333333'};
  flex:1;
  padding:10px 0;
  text-align:center;
  cursor:pointer;
  border-radius:4px;
  border: 1px solid rgba(0,0,0,0.15);
`
export const PlanBoxTile = styled.div`
  text-transform: uppercase;

`
export const PlanBoxPrice = styled.div`
  font-size:18px;
  font-weight:bold;
  padding: 10px 0;
`
export const PlanBoxPeriod = styled.div`
  font-size:12px;
  padding-bottom:10px;
`
export const PlanBoxMore = styled.div`
  display:flex;
  justify-content:center;
  padding-top:20px;
  padding-bottom:10px;
  align-items:center;
`
//end deletet


export const IntputWrapperBig = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.6rem;
`;

