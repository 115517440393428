import React, { FC } from "react";
import { IPracingProps } from "../SingIn/register.types";
import {
  PlanBoxPriceDetails,
  PlanBoxStatus,
  PlanBoxUsers,
  PlanBoxWrapper,
} from "./PlanBox.styles";
import { Aligment, Icon } from "venice-ui";
import { Colors } from "../../untils/Theme";

interface IPlanBox {
  plan: IPracingProps;
  activePlan: string;
  handleClick: (planID: string, planName: string) => void;
}

//ToDo dictionary!!
export const PlanBox: FC<IPlanBox> = ({ plan, activePlan, handleClick }) => {
  const isActive = activePlan === plan.id;
  return (
    <PlanBoxWrapper
      active={isActive}
      onClick={() => handleClick(plan.id, plan.name)}
    >
      <Aligment gap={10} direction="column">
        <div>{plan.name}</div>
        <PlanBoxUsers>5 uzytkowników</PlanBoxUsers>
        <div>
          {plan.price / 100}
          {plan.currency}
        </div>
        <PlanBoxPriceDetails> + vat / miesiąc</PlanBoxPriceDetails>
        <PlanBoxStatus>
          {isActive && (
            <Icon
              name={"check_circle"}
              size={20}
              iconColor={Colors.green}
              iconHoverColor={Colors.green}
            />
          )}
        </PlanBoxStatus>
      </Aligment>
    </PlanBoxWrapper>
  );
};
