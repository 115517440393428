import React, { FC } from "react";
import { Aligment, Card, Loader, Tile, Text } from "venice-ui";
import { AppWrapper, Content, ContentArea } from "../components";
import logo from ".././../weldingApp_logo_white.svg";
import { WelcomeLogo } from "../SingIn/Login.styles";
import { GlobalLoaderWrapper } from "../publicComponents";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";

export const Loading: FC = () => {
  
  
  return (
    <Aligment justify="center" align="center" vPadding="24px" hPadding="24px">
      <Loader size="medium" />
      <Text>
        <Aligment justify="center" align="center" vPadding="16px">
          {t(dictionary.loading)}
        </Aligment>
      </Text>
    </Aligment>
  );
};

export const LoadingCard: FC = () => {
  return (
    <Card shadow={false}>
      <Loading />
    </Card>
  );
};

export const LoadingTile: FC = () => {
  return (
    <Tile>
      <Loading />
    </Tile>
  );
};

export const LoadingContent: FC = () => {
  return (
    <Content>
      <LoadingCard />
    </Content>
  );
};

export const LoadingPage: FC = () => {
  return (
    <AppWrapper>
      <ContentArea>
        <LoadingContent />
      </ContentArea>
    </AppWrapper>
  );
};

export const GlobalLoadingPage: FC = () => {
  return (
    <GlobalLoaderWrapper>
      <WelcomeLogo src={logo} />
      <Loader size="medium" color="#ffffff" />
    </GlobalLoaderWrapper>
  );
};
