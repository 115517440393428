import { FC, useEffect, useState } from "react";
import { Loader, Modal, Table } from "venice-ui";
import { apiCall } from "../../untils/apiCall";
import { invoiceListHeader } from "../../definitions/ListDefinition";
import { IInvoicesType, getInvoiceUrl } from "../../helpers/settingsHelper";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";
import { useStoreState } from "../../store";

interface IInvoicesModalProps {
  show: boolean;
  onClose: () => void;
}
interface IInvoicesPropsType {
  loading: boolean;
  invoices: IInvoicesType[];
}

export const InvoicesModal: FC<IInvoicesModalProps> = ({ show, onClose }) => {
  const { uid } = useStoreState(
    //stripeCustomerId powinno byc w orgDetails
    // tmp

    (state) => state.user.userDetails
    );
    const stripeCustomerId = ''
  const [pageProps, updatePageProps] = useState<IInvoicesPropsType>({
    loading: true,
    invoices: [],
  });

  const getInvoices = async () => {
    const options = {
      userID: uid,
      stripeCustomerId: stripeCustomerId,
    };
    await apiCall("payments/getInvoices", options, (response: any) => {
      updatePageProps({
        loading: false,
        invoices: response.invoices,
      });
    });
  };

  useEffect(() => {
    if (show) {
      getInvoices();
    }
  }, [show]);
  const downloadInvoice = (id: string) => {
    const url = getInvoiceUrl(pageProps.invoices, id);
    const download = window.open(url, "_blank");
    if (download) {
      download.focus();
    }
  };

  return (
    <>
      {show && (
        <Modal
          title={t(dictionary.invoicesList)}
          labelClose={t(dictionary.close)}
          handleClose={onClose}
          size="medium"
        >
          {pageProps.loading ? (
            <Loader />
          ) : (
            <Table
              headers={invoiceListHeader}
              elements={pageProps.invoices}
              sortable={false}
              selectable={false}
              filtrable={false}
              onRowClick={downloadInvoice}
            />
          )}
        </Modal>
      )}
    </>
  );
};
