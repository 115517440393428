export interface IInvoicesType {
  id: string;
  number: string;
  url: string;
  price: string;
  created: number;
}

export enum PAYMENT_MODE {
  fail = "fail",
  success = "success",
  missed = "missed",
}
export type TInfoMode =
  | PAYMENT_MODE.fail
  | PAYMENT_MODE.missed
  | PAYMENT_MODE.success;

export const getInvoiceUrl = (
  invoices: IInvoicesType[],
  id: string
): string => {
  const downloadLink = invoices.find((item) => item.id === id);
  return downloadLink ? downloadLink.url : "";
};
