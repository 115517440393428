import { IListItemType } from "./configHelper";
import { degrees, PDFDocument, rgb, StandardFonts } from "pdf-lib";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { format } from "date-fns";
import { roundWidth, squareHeight, squareWidth } from "./mathHelpers";
import { IElementType } from "../definitions/element";
import {
  ADDITIONAL_TYPES,
  IAddtionalCloud,
  IAddtionalElements,
} from "../definitions/additional";

interface IExportDataFormat {
  [key: string]: number | string | boolean;
}

export const xlsxListGenrator = (
  elements: IElementType[],
  headers: IListItemType[],
  projectName: string
) => {
  const data: IExportDataFormat[] = [];
  //ToDo angielskeie napisy dodać
  elements.forEach((item) => {
    const row: IExportDataFormat = {
      "Numer rysunku": projectName,
    };

    headers.forEach((header) => {
      row[header.name] =
        (item[header.valueSource as keyof typeof item] as string | number) ||
        "";
    });

    data.push(row);
  });
  // ToDo dodać angileski
  const sheetHeaders = ["Numer rysunku"];
  headers.forEach((headItem) => {
    sheetHeaders.push(headItem.name);
  });
  const fileName = `${projectName}_${format(new Date(), "dd-MM-yyyy_HH:mm")}`;
  const ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, [sheetHeaders]);
  XLSX.utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, {
    bookType: "xlsx",
    type: "array",
    cellStyles: true,
  });
  const finalData = new Blob([excelBuffer], { type: "xlsx" });
  FileSaver.saveAs(finalData, `${fileName}.xlsx`);
};

const color = rgb(1, 0, 0);

export const setXCord = (
  x: number,
  y: number,
  width: number,
  rotation: number
) => {
  let newX = x;
  if (rotation === 90 || rotation === -270) {
    newX = y;
  } else if (rotation === 180) {
    newX = x;
  } else if (rotation === -90 || rotation === 270) {
    newX = width - y;
  }
  return newX;
};

export const setYCord = (
  x: number,
  y: number,
  height: number,
  rotation: number
) => {
  let newY = height - y;
  if (rotation === 90 || rotation === -270) {
    newY = x;
  } else if (rotation === 180) {
    newY = y;
  } else if (rotation === -90 || rotation === 270) {
    newY = height - x;
  }
  return newY;
};

export const setLabelXCord = (
  labelX: number,
  labelY: number,
  rotation: number
) => {
  let newX = labelX;
  if (rotation === 90 || rotation === -270) {
    newX = labelY;
  } else if (rotation === 180) {
    newX = -labelX;
  } else if (rotation === -90 || rotation === 270) {
    newX = -labelY;
  }
  return newX;
};

export const setLabelYCord = (
  labelX: number,
  labelY: number,
  rotation: number
) => {
  let newY = -labelY;
  if (rotation === 90 || rotation === -270) {
    newY = labelX;
  } else if (rotation === 180) {
    newY = labelY;
  } else if (rotation === -90 || rotation === 270) {
    newY = -labelX;
  }
  return newY;
};

export const setLabelTextXCord = (
  rotation: number,
  startPoint: number,
  textWidth: number
) => {
  let xCord = startPoint - textWidth / 2;
  if (rotation === 90 || rotation === -270) {
    xCord = startPoint + 5;
  } else if (rotation === 180) {
    xCord = startPoint + textWidth / 2;
  } else if (rotation === -90 || rotation === 270) {
    xCord = startPoint - 5;
  }
  return xCord;
};

export const setLabelTextYCord = (
  rotation: number,
  startPoint: number,
  textWidth: number
) => {
  let yCord = startPoint - 5;
  if (rotation === 90 || rotation === -270) {
    yCord = startPoint - textWidth / 2;
  } else if (rotation === 180) {
    yCord = startPoint + 5;
  } else if (rotation === -90 || rotation === 270) {
    yCord = startPoint + textWidth / 2;
  }
  return yCord;
};

//ToDo add additional elements here
export const pdfMapGenerator = async (
  fileUrl: string,
  fileName: string,
  elements: IElementType[],
  additional: IAddtionalElements[]
) => {
  await fetch(fileUrl)
    .then((res) => res.arrayBuffer())
    .then(async (arrayBufferData) => {
      const pdfDoc = await PDFDocument.load(arrayBufferData);
      const pages = pdfDoc.getPages();
      const contName = await pdfDoc.embedFont(StandardFonts.Helvetica);
      for (let i = 0; i < pages.length; i++) {
        const page = pages[i];
        const filteredElements = elements.filter((item) => item.page === i + 1);
        const filteredAdditional = additional.filter(
          (item) => item.page === i + 1
        );
        const rotation = page.getRotation().angle;
        const pageHeight = page.getHeight();
        const pageWidth = page.getWidth();

        const fontSize = 14;
        let textWidth = 0;
        if (filteredAdditional.length > 0) {
          filteredAdditional.forEach((element) => {
            switch (element.type) {
              case ADDITIONAL_TYPES.dot:
                const dotCordinates = setDotCordinates(
                  element,
                  pageWidth,
                  pageHeight,
                  rotation
                );
                page.drawEllipse({
                  x: dotCordinates.x,
                  y: dotCordinates.y,
                  xScale: element.size / 2,
                  yScale: element.size / 2,
                  color: color,
                });
                break;
              case ADDITIONAL_TYPES.edge:
                const edgeCordinates = setEdgeCordinates(
                  element,
                  pageWidth,
                  pageHeight,
                  rotation
                );
                page.drawLine({
                  color: color,
                  start: {
                    x: (edgeCordinates.x as number) + element.height / 2,
                    y: edgeCordinates.y as number,
                  },
                  end: {
                    x: edgeCordinates.end_x + element.height / 2,
                    y: edgeCordinates.end_y,
                  },
                  thickness: element.height,
                  opacity: 1,
                });
                break;
              case ADDITIONAL_TYPES.triage:
                const traigeCordinates = setTriageCordinates(
                  element,
                  pageWidth,
                  pageHeight,
                  rotation
                );
                const pathString = `M${traigeCordinates.x1},${traigeCordinates.y1} L${traigeCordinates.x2},${traigeCordinates.y2} L${traigeCordinates.x3},${traigeCordinates.y3} Z`;

                page.drawSvgPath(pathString, {
                  x: traigeCordinates.startX,
                  y: traigeCordinates.startY,
                  color: color,
                  opacity: 1,
                });
                break;
              case ADDITIONAL_TYPES.cloud:
                const cloudCordinates = setCloudCordinates(
                  element as IAddtionalCloud,
                  pageWidth,
                  pageHeight,
                  rotation
                );
                const pathCloudString = generateCloudPath(
                  cloudCordinates,
                  cloudCordinates.segmentWidth,
                  cloudCordinates.segmentHeight
                );
                page.drawSvgPath(pathCloudString, {
                  x: cloudCordinates.startX,
                  y: cloudCordinates.startY,
                  borderColor: color,
                  borderWidth: 2,
                });

                break;
            }
          });
        }
        if (filteredElements.length > 0) {
          filteredElements.forEach((element) => {
            const cordinates = setElementCordinates(
              element,
              pageWidth,
              pageHeight,
              rotation
            );
            page.drawEllipse({
              x: cordinates.x,
              y: cordinates.y,
              xScale: 2,
              yScale: 2,
              color: color,
            });

            page.drawLine({
              color: color,
              start: { x: cordinates.x as number, y: cordinates.y as number },
              end: { x: cordinates.line_x, y: cordinates.line_y },
              thickness: 2,
              opacity: 1,
            });

            const scale = roundWidth;
            const rectStartX = cordinates.line_x - scale / 2;
            const rectStartY = cordinates.line_y - scale / 2;
            page.drawEllipse({
              y: rectStartY,
              x: rectStartX,
              xScale: scale * 5,
              yScale: scale * 5,
              color: rgb(1, 1, 1),
              borderColor: color,
              borderWidth: 2,
            });
            try {
              textWidth = contName.widthOfTextAtSize(element.name, fontSize);
            } catch (e) {
              console.log(e);
            }

            page.drawText(element.name as string, {
              x: setLabelTextXCord(rotation, rectStartX, textWidth),
              y: setLabelTextYCord(rotation, rectStartY, textWidth),
              size: fontSize,
              rotate: degrees(rotation),
              color: rgb(0, 0, 0),
            });
          });
        }
      }
      const pdfBytes = await pdfDoc.save();
      const finalData = new Blob([pdfBytes], { type: "pdf" });
      FileSaver.saveAs(finalData, `${fileName}.pdf`);
    });
};

export const setElementCordinates = (
  element: IElementType,
  pageWidth: number,
  pageHeight: number,
  rotation: number
) => {
  const xParam = setXCord(
    element.x as number,
    element.y as number,
    pageWidth,
    rotation
  );
  const yParam = setYCord(
    element.x as number,
    element.y as number,
    pageHeight,
    rotation
  );
  const xLabelCord = setLabelXCord(
    element.label_x as number,
    element.label_y as number,
    rotation
  );
  const yLabelCord = setLabelYCord(
    element.label_x as number,
    element.label_y as number,
    rotation
  );
  return {
    x: xParam,
    y: yParam,
    label_x: xLabelCord,
    label_y: yLabelCord,
    line_x: xParam + xLabelCord,
    line_y: yParam + yLabelCord,
  };
};

export const setDotCordinates = (
  element: IAddtionalElements,
  pageWidth: number,
  pageHeight: number,
  rotation: number
) => {
  const xParam = setXCord(
    element.x as number,
    element.y as number,
    pageWidth,
    rotation
  );
  const yParam = setYCord(
    element.x as number,
    element.y as number,
    pageHeight,
    rotation
  );
  return {
    x: xParam,
    y: yParam,
  };
};

export const setEdgeCordinates = (
  element: IAddtionalElements,
  pageWidth: number,
  pageHeight: number,
  rotation: number
) => {
  const xParam = setXCord(
    element.x as number,
    element.y as number,
    pageWidth,
    rotation
  );
  const yParam = setYCord(
    element.x as number,
    element.y as number,
    pageHeight,
    rotation
  );
  const xEndParam = setXCord(
    element.end_x as number,
    element.end_y as number,
    pageWidth,
    rotation
  );
  const yEndParam = setYCord(
    element.end_x as number,
    element.end_y as number,
    pageHeight,
    rotation
  );
  return {
    x: xParam,
    y: yParam,
    end_x: xEndParam,
    end_y: yEndParam,
  };
};

const rotateVertex = (offsetX: number, offsetY: number, angleRad: number) => {
  const rotatedX = offsetX * Math.cos(angleRad) + offsetY * Math.sin(angleRad);
  const rotatedY = -offsetX * Math.sin(angleRad) + offsetY * Math.cos(angleRad);
  return [rotatedX, rotatedY];
};

export const setTriageCordinates = (
  element: IAddtionalElements,
  pageWidth: number,
  pageHeight: number,
  rotation: number
) => {
  const size = element.size;
  const angleRad = element.rotation * (Math.PI / 180);

  const vertexA = {
    x: 0,
    y: -size / 2,
  };
  const vertexB = {
    x: size / 2,
    y: size / 2,
  };
  const vertexC = {
    x: -(size / 2),
    y: size / 2,
  };

  const [rotatedX1, rotatedY1] = rotateVertex(vertexA.x, vertexA.y, angleRad);
  const [rotatedX2, rotatedY2] = rotateVertex(vertexB.x, vertexB.y, angleRad);
  const [rotatedX3, rotatedY3] = rotateVertex(vertexC.x, vertexC.y, angleRad);

  return {
    startX: setXCord(element.x, element.y, pageWidth, rotation),
    startY: setYCord(element.x, element.y, pageHeight, rotation),
    x1: setXCord(rotatedX1, rotatedY1, pageWidth, rotation),
    y1: setYCord(rotatedX1, rotatedY1, pageHeight, rotation),
    x2: setXCord(rotatedX2, rotatedY2, pageWidth, rotation),
    y2: setYCord(rotatedX2, rotatedY2, pageHeight, rotation),
    x3: setXCord(rotatedX3, rotatedY3, pageWidth, rotation),
    y3: setYCord(rotatedX3, rotatedY3, pageHeight, rotation),
  };
};

export const setCloudCordinates = (
  element: IAddtionalCloud,
  pageWidth: number,
  pageHeight: number,
  rotation: number
): IEdgeObject => {
  const angleRad = -element.rotation * (Math.PI / 180);
  const sector = 20;

  const fullWidth = element.end_x - element.x;
  const fullHeight = element.end_y - element.y;
  const availableWidth = fullWidth - sector * 2;
  const availableHeight = fullHeight - sector * 2;
  const factorWidth = parseFloat(
    Math.floor(availableWidth / sector).toFixed(2)
  );
  const factorHeight = parseFloat(
    Math.floor(availableHeight / sector).toFixed(2)
  );
  const segmentWidth = parseFloat((availableWidth / factorWidth).toFixed(2));
  const segmentHeight = parseFloat((availableHeight / factorHeight).toFixed(2));
  const offset = 10;

  const centerX = (element.end_x - element.x) / 2;
  const centerY = (element.end_y - element.y) / 2;
  const topEdge = setEgdePoints(
    segmentWidth,
    fullWidth,
    sector,
    "y",
    -centerY + offset,
    angleRad,
    pageWidth,
    pageHeight,
    rotation,
    false
  );

  const rightEdge = setEgdePoints(
    segmentHeight,
    fullHeight,
    sector,
    "x",
    fullWidth - centerX - offset,
    angleRad,
    pageWidth,
    pageHeight,
    rotation,
    false
  );
  const bottomEdge = setEgdePoints(
    segmentWidth,
    fullWidth,
    sector,
    "y",
    fullHeight - centerY - offset,
    angleRad,
    pageWidth,
    pageHeight,
    rotation,
    true
  );
  const leftEdge = setEgdePoints(
    segmentHeight,
    fullHeight,
    sector,
    "x",
    -centerX + offset,
    angleRad,
    pageWidth,
    pageHeight,
    rotation,
    true
  );
  return {
    topEdge,
    rightEdge,
    bottomEdge,
    leftEdge,
    startX: setXCord(
      element.x + centerX,
      element.y + centerY,
      pageWidth,
      rotation
    ),
    startY: setYCord(
      element.x + centerX,
      element.y + centerY,
      pageHeight,
      rotation
    ),
    segmentWidth: segmentWidth,
    segmentHeight: segmentHeight,
  };
};

interface IEdgePoints {
  x: number;
  y: number;
}

interface IEdgeObject {
  topEdge: IEdgePoints[];
  rightEdge: IEdgePoints[];
  bottomEdge: IEdgePoints[];
  leftEdge: IEdgePoints[];
  startX: number;
  startY: number;
  segmentWidth: number;
  segmentHeight: number;
}
const setEgdePoints = (
  segment: number,
  dimension: number,
  shiftValue: number,
  fixAxis: string,
  axisValue: number,
  angleRad: number,
  pageWidth: number,
  pageHeight: number,
  rotation: number,
  reverse: boolean
): IEdgePoints[] => {
  const edge: IEdgePoints[] = [];
  const range = dimension / 2;
  const start = -range + shiftValue;
  const end = range - shiftValue;

  edge.push(
    setPoint(
      start,
      fixAxis,
      axisValue,
      angleRad,
      pageWidth,
      pageHeight,
      rotation
    )
  );

  for (let i = start + segment; i < end; i += segment) {
    if (dimension - range - i >= segment) {
      edge.push(
        setPoint(
          i,
          fixAxis,
          axisValue,
          angleRad,
          pageWidth,
          pageHeight,
          rotation
        )
      );
    }
  }

  edge.push(
    setPoint(end, fixAxis, axisValue, angleRad, pageWidth, pageHeight, rotation)
  );

  return reverse ? edge.reverse() : edge;
};

const setPoint = (
  value: number,
  fixAxis: string,
  axisValue: number,
  angleRad: number,
  pageWidth: number,
  pageHeight: number,
  rotation: number
) => {
  const point = {
    x: fixAxis === "y" ? value : axisValue,
    y: fixAxis === "y" ? axisValue : value,
  };

  const [rotatedX, rotatedY] = rotateVertex(point.x, point.y, -angleRad);

  return {
    x: parseFloat(
      setXCord(rotatedX, rotatedY, pageWidth, rotation).toFixed(2).toString()
    ),
    y: parseFloat(
      setYCord(rotatedX, rotatedX, pageHeight, rotation).toFixed(2).toString()
    ),
  };
};

interface IEdgePoint {
  x: number;
  y: number;
}

interface IEdgeObject {
  leftEdge: IEdgePoint[];
  topEdge: IEdgePoint[];
  rightEdge: IEdgePoint[];
  bottomEdge: IEdgePoint[];
}

const generateArcPath = (
  startPoint: IEdgePoint,
  points: IEdgePoint[],
  radius: number
) => {
  let path = `A ${radius} ${radius} 0 1 0 ${startPoint.x},${startPoint.y} `;
  points.forEach((point, index) => {
    if (index !== 0) {
      path += `A ${radius} ${radius} 0 1 0 ${point.x},${point.y} `;
    }
  });
  return path;
};

export const generateCloudPath = (
  edgeObj: IEdgeObject,
  segmentWidth: number,
  segmentHeight: number
): string => {
  const radiusWidth = segmentWidth / 2;
  const radiusHeight = segmentHeight / 2;

  let pathString = `M${edgeObj.leftEdge[edgeObj.leftEdge.length - 1].x},${
    edgeObj.leftEdge[edgeObj.leftEdge.length - 1].y
  } `;

  pathString += generateArcPath(
    edgeObj.topEdge[0],
    edgeObj.topEdge,
    radiusWidth
  );
  pathString += generateArcPath(
    edgeObj.rightEdge[0],
    edgeObj.rightEdge,
    radiusHeight
  );
  pathString += generateArcPath(
    edgeObj.bottomEdge[0],
    edgeObj.bottomEdge,
    radiusWidth
  );
  pathString += generateArcPath(
    edgeObj.leftEdge[0],
    edgeObj.leftEdge,
    radiusHeight
  );

  pathString += "Z";
  return pathString;
};
