import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Section, Aligment, TextLabel, Text, Icon } from "venice-ui";
import { Content, Link } from "../components";
import { SettingRow } from "./Settings.styles";
import packageJson from "../../../package.json";
import {  t } from "../../helpers/userHelper";
import { apiCall } from "../../untils/apiCall";
import { DeleteUserModal, InvoicesModal } from "../Modals";
import { getAuth } from "firebase/auth";
import { dictionary } from "../../untils/dictionary";
import { useStoreState } from "../../store";

export const Settings: FC = () => {
  const { email, accountType, orgRole, uid } =
    useStoreState((state) => state.user.userDetails);

  const [invoicesModal, toogleInvoicesModal] = useState(false);
  const [deleteUserModal, toogleDeleteUserModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [pageData, setPageData] = useState({
    sessionLink: "",
    editAddressLink: "",
    address: {
      name: "",
      line1: "",
      line2: "",
      postal_code: "",
      city: "",
      state: "",
      country: "",
    },
  });

  const getCustomerPortal = async () => {
    if (orgRole === "admin") {
      const options = {
        //ToDO dodac ty dane z bazy
        sessionID: `stripeSession`,
        userStripeID: `stripeCustomerId`,
      };
      await apiCall("register/getUserSettings", options, (response: any) => {
        setPageData({
          sessionLink: response.portalSession.url,
          editAddressLink: response.userDetails.editAddressUrl,
          address: {
            name: response.userDetails.name,
            line1: response.userDetails.address.line1 || "",
            line2: response.userDetails.address.line2 || "",
            postal_code: response.userDetails.address.postal_code || "",
            city: response.userDetails.address.city || "",
            state: response.userDetails.address.state || "",
            country: response.userDetails.address.country || "",
          },
        });
      });
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
      getCustomerPortal();
    
  }, []);

  const deleteUser = async () => {
    setDeleteLoader(true);

    const options = {
      userID: uid,
    };
    await apiCall("register/deleteUser", options, (response: any) => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (response.error) {
        navigate("/server-error");
      }
      if (user && !response.error) {
        user
          .delete()
          .then(() => {
            navigate("/");
          })
          .catch((error) => {
            // An error ocurred
            // ...
          });
      }
    });
  };

  return (
    <Content>
      <Card title={t(dictionary.setting)} handleBack={() => navigate(-1)} shadow={false}>
        <Aligment direction="row">
          <Section noPadding={true} title={t(dictionary.account)}>
            <Aligment direction="column" align="flex-start">
              <SettingRow>
                <TextLabel>{t(dictionary.email)}</TextLabel>
                <Text>{email}</Text>
              </SettingRow>
            </Aligment>
          </Section>
          {orgRole !== "admin" && (
            <Section title={t(dictionary.payment)} noPadding={true}>
              <Aligment direction="column" align="flex-start">
                <SettingRow>
                  <TextLabel>{t(dictionary.billingAddress)}</TextLabel>
                  <Aligment
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                  >
                    <Text>{pageData.address.name}</Text>
                    <Text>{pageData.address.line1}</Text>
                    <Text>{pageData.address.line2}</Text>
                    <Text>
                      {pageData.address.postal_code} {pageData.address.city}
                    </Text>
                    <Text>{pageData.address.state}</Text>
                    <Text>{pageData.address.country}</Text>

                    <Link href={pageData.editAddressLink} target="_blank">
                      {t(dictionary.edit)}
                      <Icon size={14} name="new_tab" />
                    </Link>
                  </Aligment>
                </SettingRow>

                <SettingRow>
                  <Link href={pageData.sessionLink} target="_blank">
                    {t(dictionary.billingSettings)}
                    <Icon size={14} name="new_tab" />
                  </Link>
                  <Text onClick={() => toogleInvoicesModal(true)} action>
                    {t(dictionary.invoices)}
                  </Text>
                </SettingRow>
              </Aligment>
            </Section>
          )}
          <Section title={t(dictionary.administration)} noPadding={true}>
            <Text onClick={() => toogleDeleteUserModal(true)} action>
              {t(dictionary.deleteAccount)}
            </Text>
          </Section>
          {t(dictionary.appVersion)}: {packageJson.version}
        </Aligment>
      </Card>
      <InvoicesModal
        show={invoicesModal}
        onClose={() => toogleInvoicesModal(false)}
      />
      {deleteUserModal && (
        <DeleteUserModal
          handleCancel={() => toogleDeleteUserModal(false)}
          handleDelete={deleteUser}
          loader={deleteLoader}
        />
      )}
    </Content>
  );
};
