import { action } from "easy-peasy";
import { IOrganization } from "../interface/organization";

export const organization: IOrganization = {
  id: "",
  name: "",
  nameCode: "",
  creationTime: 0,
  activeTime: 0,
  members: [],
  contracts: [],
  planKey: "",
  invitations: [],

  setOrgDetails: action((state, payload) => {
    state.id = payload.id;
    state.name = payload.name;
    state.nameCode = payload.nameCode;
    state.creationTime = payload.creationTime;
    state.activeTime = payload.activeTime;
    state.members = payload.members;
    state.contracts = payload.contracts || [];
    state.planKey = payload.planKey;
    state.invitations = payload.invitations || [];
  }),

  addContract: action((state, payload) => {
    const newContracts = [...state.contracts, payload];
    state.contracts = newContracts;
  }),

  removeContract: action((state, payload) => {
    const newContracts = state.contracts.filter((item) => item.id !== payload);
    state.contracts = newContracts;
  }),

  removeMemeberFromContract: action((state, payload) => {
    const newContracts = [...state.contracts];
    const contact = newContracts.find((item) => item.id === payload.contractID);
    if (contact) {
      contact.members = contact.members.filter(
        (item) => item !== payload.memberID
      );
    }
    state.contracts = newContracts;
  }),

  addMemeberFromContract: action((state, payload) => {
    const newContracts = [...state.contracts];
    const contact = newContracts.find((item) => item.id === payload.contractID);
    if (contact) {
      contact.members = [...contact.members, payload.memberID];
    }
    state.contracts = newContracts;
  }),

  editContractName: action((state, payload) => {
    const newContracts = [...state.contracts];
    const contact = newContracts.find((item) => item.id === payload.contractID);
    if (contact) {
      contact.name = payload.name;
    }
    state.contracts = newContracts;
  }),

  saveMemberRole: action((state, payload) => {
    const newMembers = [...state.members];
    const change = newMembers.find((item) => item.id === payload.memberID);
    if (change) {
      change.role = payload.role;
    }
    state.members = newMembers;
  }),

  addInvitation: action((state, payload) => {
    const newInvitations = [...state.invitations, payload];
    state.invitations = newInvitations;
  }),

  removeInvitation: action((state, payload) => {
    const newInvitations = state.invitations.filter((item) => item.id !== payload)
    state.invitations = newInvitations;
  }),
};
