import React, { FC, useEffect, useState } from "react";
import { Card, Aligment, Text, Section, Icon } from "venice-ui";
import { Link } from "../../components";
import { PAYMENT_MODE, TInfoMode } from "../../../helpers/settingsHelper";
import { useNavigate } from "react-router-dom";
import { t } from "../../../helpers/userHelper";
import { dictionary } from "../../../untils/dictionary";

interface IMissedPaymentInfo {
  mode: TInfoMode;
  sessionLink?: string;
}
export const PaymentInfo: FC<IMissedPaymentInfo> = ({ sessionLink, mode }) => {
  const [timer, setTimer] = useState(5);
  const navigate = useNavigate();

  const getTitle = () => {
    switch (mode) {
      case PAYMENT_MODE.success:
        return t(dictionary.paymentTitleSuccess);
      case PAYMENT_MODE.fail:
        return t(dictionary.paymentTitleFail);
      case PAYMENT_MODE.missed:
        return t(dictionary.paymentTitleMissed);
    }
  };

  const getContent = () => {
    switch (mode) {
      case PAYMENT_MODE.success:
        return t(dictionary.paymentContentSuccess);
      case PAYMENT_MODE.fail:
        return t(dictionary.paymentContentFail);
      case PAYMENT_MODE.missed:
        return t(dictionary.paymentContentMissed);
    }
  };

  useEffect(() => {
    if (mode === PAYMENT_MODE.success) {
      const interval = setInterval(() => {
        if (timer > 0) {
          setTimer(timer - 1);
        } else if (timer === 0) {
          navigate("/");
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  return (
    <Card title={getTitle()} shadow={false}>
      <Section>
        <Aligment direction="column" gap={20}>
          <Icon
            name={mode === PAYMENT_MODE.success ? "check_circle" : "error"}
            size={56}
            iconColor={mode === PAYMENT_MODE.success ? "#3da32f" : "#ad1616"}
          />
          <Text>{getContent()}</Text>
          {mode === PAYMENT_MODE.success ? (
            <Text>
              Za {timer} sekund nastąpi przekierowanie do strony głównej.
            </Text>
          ) : (
            <Link href={sessionLink} target="_blank">
              Zaaktualizuj dane karty.
            </Link>
          )}
        </Aligment>
      </Section>
    </Card>
  );
};
