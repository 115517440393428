import styled from "styled-components";
import {
  roundHeight,
  roundWidth,
  squareHeight,
  squareWidth,
} from "../../helpers/mathHelpers";
import { Colors } from "../../untils/Theme";

interface IItemPointProps {
  active: boolean;
  isEditMode: boolean;
}

export const ItemPoint = styled.div<IItemPointProps>`
  width: 0.4rem;
  height: 0.4rem;
  z-index: ${(p) => (p.active ? 50 : p.isEditMode ? 20 : 40)};
  position: absolute;
  border-radius: 50%;
  background-color: ${(p) => (p.active ? Colors.seaBlue : Colors.red)};
`;
export const PointLine = styled.div<IItemPointProps>`
  position: relative;
  z-index: ${(p) => (p.active ? 50 : p.isEditMode ? 15 : 35)};
  transform-origin: 0.1rem 0.1rem;
  position: absolute;
  width: 0.2rem;
  background-color: ${(p) => (p.active ? Colors.seaBlue : Colors.red)};
  top: 0.1rem;
  left: 0.1rem;
`;

interface IItemLableProps {
  round?: boolean;
  active: boolean;
  label: string;
  move: boolean;
  isEditMode: boolean;
}
export const PointLabel = styled.div<IItemLableProps>`
  position: relative;
  width: ${ roundWidth + "rem"};
  height: ${roundHeight + "rem"};
  border-radius: ${(p) => (p.round ? "50%" : "0.2rem")};
  font-size: 1.4rem;
  border: 0.2rem solid ${(p) => (p.active ? Colors.seaBlue : Colors.red)};
  text-align: center;
  background-color: ${Colors.white};
  z-index: ${(p) => (p.active ? 50 : p.isEditMode ? 20 : 40)};
  display: flex;
  justify-content: center;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: ${(p) => (p.move ? "grabbing" : "pointer")};

  &::before {
    width: calc(
      ${roundWidth + "rem"} - 0.4rem
    );
    height: calc(
      ${roundHeight + "rem"} - 0.4rem
    );
    content: "${(p) => p.label}";
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 1.4rem;
  }
`;
export const IconWrapper = styled.div`
  position: absolute;
  z-index: 55;
  display: flex;
  justify-content: center;
  .material-symbols-outlined {
    font-size: 1.4rem;
    line-height: 1.4rem;
    font-weight: normal;
  }
  ::selection {
    background: transparent;
  }
`;
