import { t } from "../helpers/userHelper";
import { dictionary } from "../untils/dictionary";
import { IItemType } from "./element";

export interface IJointType extends IItemType {
  joint_type: string;
  element_type: string;
  diameter_1: number;
  diameter_2: number;
  thickness_1: number;
  thickness_2: number;
  material_1: string;
  material_2: string;
  sourceElementID?: string;
}

export const getJointDefinition = (data?: IJointType): IJointType => {
  return {
    id: data?.id || "",
    name: data?.name || "",
    sectionID: data?.sectionID || "",
    page: data?.page || 0,
    x: data?.x || 0,
    y: data?.y || 0,
    label_x: data?.label_x || 0,
    label_y: data?.label_y || 0,
    type: data?.type || "joint",
    lock: data?.lock || false,
    joint_type: data?.joint_type || "",
    element_type: data?.element_type || "",
    diameter_1: data?.diameter_1 || 0,
    diameter_2: data?.diameter_2 || 0,
    thickness_1: data?.thickness_1 || 0,
    thickness_2: data?.thickness_2 || 0,
    material_1: data?.material_1 || "",
    material_2: data?.material_2 || "",
    createdDate: data?.createdDate || 0,
    sourceElementID:data?.sourceElementID || undefined
  };
};

export interface IJointFormType extends IItemType {
  joint_type: string;
  element_type: string;
  diameter_1: string;
  diameter_2: string;
  thickness_1: string;
  thickness_2: string;
  material_1: string;
  material_2: string;
  sourceElementID?:string
}

export const getJointFormDefinition = (data?: IJointType): IJointFormType => {
  return {
    id: data?.id || "",
    name: data?.name || "",
    sectionID: data?.sectionID || "",
    page: data?.page || 0,
    x: data?.x || 0,
    y: data?.y || 0,
    label_x: data?.label_x || 0,
    label_y: data?.label_y || 0,
    type: data?.type || "joint",
    lock: data?.lock || false,
    joint_type: data?.joint_type || "",
    element_type: data?.element_type || "",
    diameter_1: data?.diameter_1.toString() || "",
    diameter_2: data?.diameter_2.toString() || "",
    thickness_1: data?.thickness_1.toString() || "",
    thickness_2: data?.thickness_2.toString() || "",
    material_1: data?.material_1 || "",
    material_2: data?.material_2 || "",
    createdDate: data?.createdDate || 0,
    sourceElementID: data?.sourceElementID || undefined
  };
};

export const getJointForm = (state: IJointType) => {
  return [
    {
      row: [
        {
          type: "text",
          label: t(dictionary.jointNumber),
          value: state.name,
          name: "name",
        },
      ],
    },
    {
      row: [
        {
          type: "text",
          label: t(dictionary.jointType),
          value: state.joint_type,
          name: "joint_type",
        },
        {
          type: "text",
          label: t(dictionary.element_type),
          value: state.element_type,
          name: "element_type",
        },
      ],
    },
    {
      row: [
        {
          type: "number",
          label: t(dictionary.diameter_1),
          value: state.diameter_1,
          name: "diameter_1",
        },
        {
          type: "number",
          label: t(dictionary.diameter_2),
          value: state.diameter_2,
          name: "diameter_2",
        },
      ],
    },
    {
      row: [
        {
          type: "number",
          label: t(dictionary.thickness_1),
          value: state.thickness_1,
          name: "thickness_1",
        },
        {
          type: "number",
          label: t(dictionary.thickness_2),
          value: state.thickness_2,
          name: "thickness_2",
        },
      ],
    },
    {
      row: [
        {
          type: "text",
          label: t(dictionary.material_1),
          value: state.material_1,
          name: "material_1",
        },
        {
          type: "text",
          label: t(dictionary.material_2),
          value: state.material_2,
          name: "material_2",
        },
      ],
    },
  ];
};

export const getJointDetails = (state: IJointType) => {
  return [
    {
      label: t(dictionary.jointNumber),
      value: state.name,
      type: "text",
    },
    {
      label: t(dictionary.jointType),
      value: state.joint_type,
      type: "text",
    },
    {
      label: t(dictionary.element_type),
      value: state.element_type,
      type: "text",
    },
    {
      label: t(dictionary.diameter_1),
      value: state.diameter_1,
      type: "number",
    },
    {
      label: t(dictionary.diameter_2),
      value: state.diameter_2,
      type: "number",
    },
    {
      label: t(dictionary.thickness_1),
      value: state.thickness_1,
      type: "number",
    },
    {
      label: t(dictionary.thickness_2),
      value: state.thickness_2,
      type: "number",
    },
    {
      label: t(dictionary.material_1),
      value: state.material_1,
      type: "text",
    },
    {
      label: t(dictionary.material_2),
      value: state.material_2,
      type: "text",
    },
  ];
};
export const jointValidatorFileds: string[] = [
  "name",
  "joint_type",
  "element_type",
  "material_1",
  "material_2",
  "diameter_1",
  "diameter_2",
  "thickness_1",
  "thickness_2",
];
