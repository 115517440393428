import {
  IOrgContract,
  IOrgContractDetails,
  IOrgMember,
  IOrgMemberSimple,
} from "../store/interface/organization";
import { UserContract } from "../store/interface/user";

export const getContractMembers = (
  id: string,
  contracts: IOrgContract[],
  members: IOrgMember[]
): IOrgMember[] => {
  const orgMembers: IOrgMemberSimple[] = [];
  const finded = contracts.find((item) => item.id === id);
  if (finded) {
    if (finded.members) {
      finded.members.forEach((memberID) => {
        const member = members.find((member) => member.id === memberID);
        if (member) {
          orgMembers.push({
            id: member.id,
            email: member.email,
          });
        }
      });
    }
  }
  return orgMembers;
};

export const getUnassignMemeber = (
  contractID: string,
  contracts: IOrgContract[],
  members: IOrgMember[]
): IOrgMember[] => {
  const unassingMemebers: IOrgMember[] = [];
  const finded = contracts.find((item) => item.id === contractID);
  if (finded) {
    members.forEach((member) => {
      if (!finded.members.includes(member.id)) {
        unassingMemebers.push(member);
      }
    });
  }

  return unassingMemebers;
};

export const checkIfUserHaveContract = (
  contractID: string,
  contracts: UserContract[]
) => {
  return contracts.some((_contract) => _contract.id === contractID);
};

export const getMemberDetails = (members: IOrgMember[], memberID: string) => {
  const member = {
    email: "",
    role: "",
  };
  const found = members.find((_member) => _member.id === memberID);
  if (found) {
    member.email = found.email;
    member.role = found.role || "";
  }
  return member;
};

export const getContractsForMemeber = (
  memberID: string,
  contracts: IOrgContract[]
) => {
  const userContracts: UserContract[] = [];
  contracts.forEach((_contract) => {
    if (_contract.members.includes(memberID)) {
      userContracts.push({ name: _contract.name, id: _contract.id });
    }
  });
  return userContracts;
};

export const getUnassignContracts = (
  memberID: string,
  contracts: IOrgContract[]
): IOrgContract[] => {
  return contracts.filter((_contract) => !_contract.members.includes(memberID));
};
