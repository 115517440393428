import { FC } from "react";
import { Content } from "../components";
import { Card } from "venice-ui";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
import { EmptyState } from "../UI/EmptyState";
import { useNavigate } from "react-router-dom";

export const EmptyContracts: FC = () => {
    const navigate = useNavigate();

    const goToContrats = ()=>{
        navigate(`/organization/contrats/add`);
    }
  return (
    <Content>
      <Card
        title={t(dictionary.projects)}
        shadow={false}
        height="calc(100vh - 6rem)"
      >
        <EmptyState
            title={t(dictionary.emptyStateStartPage)}
            actionLabel={t(dictionary.addContract)}
            action={() => goToContrats()}
          />
      </Card>
    </Content>
  );
};
