import React, { FC, ReactText, useState } from "react";
import { Aligment, Input, Modal, Text } from "venice-ui";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";
import { useStoreState } from "../../store";
import { IntputWrapperBig } from "../SingIn/Login.styles";

interface IDeleteProjectModalProps {
  handleCancel: () => any;
  handleDelete: () => void;
  loader: boolean;
}

export const DeleteUserModal: FC<IDeleteProjectModalProps> = ({
  handleCancel,
  handleDelete,
  loader,
}) => {
  const { email } = useStoreState((state) => state.user.userDetails);
  const [pageData, setPageData] = useState({
    email: "",
  });

  const setEmailValue = (field: string, value: string) => {
    setPageData({
      ...pageData,
      [field]: value,
    });
  };

  const validation = () => {
    return pageData.email !== email;
  };
  const onClose = () => {
    if (!loader) {
      handleCancel();
    }
  };
  return (
    <Modal
      title={t(dictionary.confirmDelete)}
      labelClose={t(dictionary.cancel)}
      handleClose={onClose}
      handleConfirm={handleDelete}
      labelConfirm={t(dictionary.closeAccount)}
      submitDisabled={validation()}
      submitLoader={loader}
    >
      <Aligment gap={20}>
        <Text>{t(dictionary.doYouWantDeleteAccount)}</Text>
        <Text>{t(dictionary.confirmAccountDelete)}</Text>
        <IntputWrapperBig>
          <Input
            label={t(dictionary.enterEmailAddress)}
            type="text"
            labelPosition="top"
            value={pageData.email}
            name="login"
            handleChange={(name: string, value: ReactText) =>
              setEmailValue("email", value as string)
            }
          />
        </IntputWrapperBig>
      </Aligment>
    </Modal>
  );
};
