import React, { FC, useEffect, useState } from "react";
import { Form, Modal } from "venice-ui";
import { apiCall } from "../../untils/apiCall";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
import { useStoreState } from "../../store";

export interface EditProjectData {
  show: boolean;
  id: string;
  name: string;
  contract: string;
}

interface IEditProjectModalProps {
  onClose: () => void;
  onConfirm: (newData: EditProjectData) => void;
  dataSet: EditProjectData;
}

export const EditProjectModal: FC<IEditProjectModalProps> = ({
  onClose,
  onConfirm,
  dataSet,
}) => {
  const { uid,orgID, contracts } = useStoreState((state) => state.user.userDetails);
  const [editData, setEditData] = useState<EditProjectData>({
    show: true,
    id: dataSet.id,
    name: dataSet.name,
    contract: dataSet.contract,
  });

  useEffect(() => {
    setEditData({ ...dataSet });
  }, [dataSet]);

  const validationNewProject = () => {
    const name = editData.name as string;
    return name.trim().length === 0;
  };

  const updateForm = (field: string, value: string) => {
    setEditData({
      ...editData,
      [field]: value,
    });
  };
  const updateProject = async () => {
    const options = {
      orgID: orgID,
      projectID: editData.id,
      name: editData.name.trim(),
      contract: editData.contract,
    };
    await apiCall("projects/updateProject", options, () => {
      onConfirm(editData);
    });
  };
  const contractsScope = contracts.map((item) => {
    return { value: item.id, label: item.name };
  });
  const editForm = {
    action: updateForm,
    data: [
      {
        type: "text",
        label: t(dictionary.projectName),
        value: editData?.name,
        name: "name",
        autofocus: true,
        submit: () => updateProject(),
      },
      {
        type: "select",
        label: t(dictionary.contract),
        value: editData.contract,
        options: contractsScope,
        zIndex: 1000,
        position: "left",
        name: "contract",
      },
    ],
  };
  return (
    <>
      {editData.show && (
        <Modal
          title={`${t(dictionary.editModalTitle)}: ${editData.name}`}
          handleClose={onClose}
          handleConfirm={() => updateProject()}
          labelClose={t(dictionary.cancel)}
          labelConfirm={t(dictionary.save)}
          submitDisabled={validationNewProject()}
        >
          <Form formData={editForm} />
        </Modal>
      )}
    </>
  );
};
