import { t } from "../helpers/userHelper";
import { dictionary } from "../untils/dictionary";

export interface ITableHeaderProps {
  name: string;
  valueSource: string;
  sort?: boolean;
  date?: boolean;
}

export const invoiceListHeader: ITableHeaderProps[] = [
  {
    name: t(dictionary.invoiceNumber),
    valueSource: "number",
  },
  {
    name: t(dictionary.date),
    valueSource: "created",
    date: true,
  },
  {
    name: t(dictionary.price),
    valueSource: "price",
  },
];



export const jointHeaders: ITableHeaderProps[] = [
  {
    name: t(dictionary.jointNumber),
    valueSource: "name",
  },
  {
    name: t(dictionary.jointType),
    valueSource: "joint_type",
  },
  {
    name: t(dictionary.page),
    valueSource: "page",
  },
  {
    name: t(dictionary.element_type),
    valueSource: "element_type",
  },
  {
    name: t(dictionary.diameter_1),
    valueSource: "diameter_1",
  },
  {
    name: t(dictionary.thickness_1),
    valueSource: "thickness_1",
  },
  {
    name: t(dictionary.material_1),
    valueSource: "material_1",
  },
  {
    name: t(dictionary.diameter_2),
    valueSource: "diameter_2",
  },
  {
    name: t(dictionary.thickness_2),
    valueSource: "thickness_2",
  },
  {
    name: t(dictionary.material_2),
    valueSource: "material_2",
  },
];


export const pointsListHeaders: ITableHeaderProps[] = [
  {
    name: t(dictionary.jointNumber),
    valueSource: "name",
  },
  {
    name: t(dictionary.kks),
    valueSource: "kks",
  },
  {
    name: t(dictionary.page),
    valueSource: "page",
  },
  {
    name: t(dictionary.jointType),
    valueSource: "joint_type",
  },
  {
    name: t(dictionary.element_type),
    valueSource: "element_type",
  },
  {
    name: t(dictionary.diameter_1),
    valueSource: "diameter_1",
  },
  {
    name: t(dictionary.thickness_1),
    valueSource: "thickness_1",
  },
  {
    name: t(dictionary.material_1),
    valueSource: "material_1",
  },
  {
    name: t(dictionary.diameter_2),
    valueSource: "diameter_2",
  },
  {
    name: t(dictionary.thickness_2),
    valueSource: "thickness_2",
  },
  {
    name: t(dictionary.material_2),
    valueSource: "material_2",
  },
  {
    name: t(dictionary.weldingDate),
    valueSource: "weldingDate",
  },
  {
    name: t(dictionary.welder),
    valueSource: "welder",
  },
  {
    name: t(dictionary.materialCertificate_1),
    valueSource: "materialCertificate_1",
  },
  {
    name: t(dictionary.materialCertificate_2),
    valueSource: "materialCertificate_2",
  },
  {
    name: t(dictionary.visualExamination),
    valueSource: "visualExamination",
  },
  {
    name: t(dictionary.visualExaminationDate),
    valueSource: "visualExaminationDate",
  },
  {
    name: t(dictionary.xRayExamination),
    valueSource: "xRayExamination",
  },
  {
    name: t(dictionary.xRayExaminationDate),
    valueSource: "xRayExaminationDate",
  },
  {
    name: t(dictionary.weldingDate),
    valueSource: "weldingDate",
  },
  {
    name: t(dictionary.welder),
    valueSource: "welder",
  },
  {
    name: t(dictionary.materialCertificate_1),
    valueSource: "materialCertificate_1",
  },
  {
    name: t(dictionary.materialCertificate_2),
    valueSource: "materialCertificate_2",
  },
  {
    name: t(dictionary.visualExamination),
    valueSource: "visualExamination",
  },
  {
    name: t(dictionary.visualExaminationDate),
    valueSource: "visualExaminationDate",
  },
  {
    name: t(dictionary.xRayExamination),
    valueSource: "xRayExamination",
  },
  {
    name: t(dictionary.xRayExaminationDate),
    valueSource: "xRayExaminationDate",
  },];


  export const sectionListHeaders: ITableHeaderProps[] = [
    {
      name: t(dictionary.jointNumber),
      valueSource: "name",
    },
    {
      name: t(dictionary.page),
      valueSource: "page",
    },
    {
      name: t(dictionary.jointType),
      valueSource: "joint_type",
    },
    {
      name: t(dictionary.element_type),
      valueSource: "element_type",
    },
    {
      name: t(dictionary.diameter_1),
      valueSource: "diameter_1",
    },
    {
      name: t(dictionary.thickness_1),
      valueSource: "thickness_1",
    },
    {
      name: t(dictionary.material_1),
      valueSource: "material_1",
    },
    {
      name: t(dictionary.diameter_2),
      valueSource: "diameter_2",
    },
    {
      name: t(dictionary.thickness_2),
      valueSource: "thickness_2",
    },
    {
      name: t(dictionary.material_2),
      valueSource: "material_2",
    },
    {
      name: t(dictionary.weldingDate),
      valueSource: "weldingDate",
    },
    {
      name: t(dictionary.welder),
      valueSource: "welder",
    },
    {
      name: t(dictionary.materialCertificate_1),
      valueSource: "materialCertificate_1",
    },
    {
      name: t(dictionary.materialCertificate_2),
      valueSource: "materialCertificate_2",
    },
    {
      name: t(dictionary.visualExamination),
      valueSource: "visualExamination",
    },
    {
      name: t(dictionary.visualExaminationDate),
      valueSource: "visualExaminationDate",
    },
    {
      name: t(dictionary.xRayExamination),
      valueSource: "xRayExamination",
    },
    {
      name: t(dictionary.xRayExaminationDate),
      valueSource: "xRayExaminationDate",
    },
    {
      name: t(dictionary.weldingDate),
      valueSource: "weldingDate",
    },
    {
      name: t(dictionary.welder),
      valueSource: "welder",
    },
    {
      name: t(dictionary.materialCertificate_1),
      valueSource: "materialCertificate_1",
    },
    {
      name: t(dictionary.materialCertificate_2),
      valueSource: "materialCertificate_2",
    },
    {
      name: t(dictionary.visualExamination),
      valueSource: "visualExamination",
    },
    {
      name: t(dictionary.visualExaminationDate),
      valueSource: "visualExaminationDate",
    },
    {
      name: t(dictionary.xRayExamination),
      valueSource: "xRayExamination",
    },
    {
      name: t(dictionary.xRayExaminationDate),
      valueSource: "xRayExaminationDate",
    },];